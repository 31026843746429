import { useFormikContext, FieldArray } from 'formik';
import { EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiFieldText, EuiRadioGroup } from '@elastic/eui';
import { StyledSpacer, StyledEuiHorizontalRule, StyledEuiLink, StyledEuiButton } from '../Global/StyledComponents';
import { TYPE_LOAN_INFO } from '../../constants/formOptions';
import { handleInputChange, handleCurrencyBlur, handlePercentageBlur } from '../../utils/currencyFields';

type CompleteLoanFormValues = {
    debtAccounts: {
        balance: string,
        interestRate: string,
        loanInfoType: string,
        minPayment: string,
        loanDetails: {
            currentBalance: string,
            interestRate: string,
        }[];
    }[];
};

function CompleteLoanDetails() {

    const { values, setFieldValue } = useFormikContext<CompleteLoanFormValues>();

    return (
        <>
            <StyledSpacer size='32px' />

            <EuiFormRow
                label="Do you want to enter an estimated balance or complete loan details?"
                helpText="Entering complete loan details will make our recommendations more accurate."
            >
                <EuiRadioGroup
                    name="loanInfoType"
                    options={TYPE_LOAN_INFO}
                    idSelected={values.debtAccounts[0].loanInfoType}
                    onChange={value => setFieldValue('debtAccounts[0].loanInfoType', value)}
                />

            </EuiFormRow>

            {values.debtAccounts[0].loanInfoType === 'Estimated balance' && (
                <>
                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label="Balance"
                        className='input-size'
                    >
                        <EuiFieldText
                            name="balance"
                            value={values.debtAccounts[0].balance}
                            onChange={handleInputChange('debtAccounts[0].balance', setFieldValue)}
                            onBlur={handleCurrencyBlur('debtAccounts[0].balance', setFieldValue)}
                        />

                    </EuiFormRow>

                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label="Interest Rate"
                        className='input-size'
                    >
                        <EuiFieldText
                            name="interestRate"
                            value={values.debtAccounts[0].interestRate}
                            onChange={handleInputChange('debtAccounts[0].interestRate', setFieldValue)}
                            onBlur={handlePercentageBlur('debtAccounts[0].interestRate', setFieldValue)}
                        />
                    </EuiFormRow>

                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label="Minimum Payment"
                        className='input-size'
                    >
                        <EuiFieldText
                            name="minPayment"
                            value={values.debtAccounts[0].minPayment}
                            onChange={handleInputChange('debtAccounts[0].minPayment', setFieldValue)}
                            onBlur={handleCurrencyBlur('debtAccounts[0].minPayment', setFieldValue)}
                        />
                    </EuiFormRow>
                </>
            )}

            {values.debtAccounts[0].loanInfoType === 'Complete loan details' && (
                <>
                    <StyledSpacer size='32px' />

                    <EuiFormRow label="Minimum Payment" className='input-size'>
                        <EuiFieldText
                            name="minPayment"
                            value={values.debtAccounts[0].minPayment}
                            onChange={handleInputChange('debtAccounts[0].minPayment', setFieldValue)}
                            onBlur={handleCurrencyBlur('debtAccounts[0].minPayment', setFieldValue)}
                        />
                    </EuiFormRow>

                    <StyledSpacer size='32px' />

                    <FieldArray name="debtAccounts[0].loanDetails">
                        {({ push, remove }) => (
                            <>
                                {values.debtAccounts[0].loanDetails.map((loan, index) => (
                                    <div key={index}>
                                        <EuiFlexGroup style={{ maxWidth: 428, marginTop: 16 }} className='mobile-field-row'>
                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow label={`Loan ${index + 1} Balance`}>
                                                    <EuiFieldText
                                                        name={`debtAccounts[0].loanDetails[${index}].currentBalance`}
                                                        value={values.debtAccounts[0].loanDetails[index].currentBalance}
                                                        onChange={handleInputChange(`debtAccounts[0].loanDetails[${index}].currentBalance`, setFieldValue)}
                                                        onBlur={handleCurrencyBlur(`debtAccounts[0].loanDetails[${index}].currentBalance`, setFieldValue)}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>

                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow label={`Loan ${index + 1} Interest Rate`}>
                                                    <EuiFieldText
                                                        name={`debtAccounts[0].loanDetails[${index}].interestRate`}
                                                        value={values.debtAccounts[0].loanDetails[index].interestRate}
                                                        onChange={handleInputChange(`debtAccounts[0].loanDetails[${index}].interestRate`, setFieldValue)}
                                                        onBlur={handlePercentageBlur(`debtAccounts[0].loanDetails[${index}].interestRate`, setFieldValue)}
                                                    />

                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>

                                        <StyledSpacer size="24px" />

                                        <StyledEuiLink color="primary" onClick={() => remove(index)}>
                                            Delete
                                        </StyledEuiLink>

                                        <StyledSpacer size="32px" />

                                        <StyledEuiHorizontalRule maxWidth="428px" />

                                        <StyledSpacer size="32px" />
                                    </div>
                                ))}

                                <StyledEuiButton color='text' iconType="plus" onClick={() => push({ currentBalance: '', interestRate: '' })}>
                                    Add Federal student loan
                                </StyledEuiButton>
                            </>
                        )}
                    </FieldArray>
                </>
            )}


        </>
    );
}

export default CompleteLoanDetails;
