import { useState, useRef } from 'react';
import {
    EuiPageBody,
    EuiFlyout,
    EuiFlyoutHeader,
    EuiFlyoutBody,
    EuiTitle,
    EuiIcon,
    EuiText,
    EuiButton,
    EuiFlyoutFooter,
    EuiSpacer
} from '@elastic/eui';
import { Container } from 'react-bootstrap';
import styled from '@emotion/styled';
import PersonalInfoLanding from '../views/PersonalInfoLanding';
import FinancialInfoLanding from '../views/FinancialInfoLanding';
import FlyoutInfo from '../components/Global/FlyoutInfo';
import Flyout from '../components/Global/Flyout';
import { useFlyout } from '../components/Global/FlyoutContext';
import PersonalInfo from '../components/PersonalInfo/PersonalInfo';
import EducationInfo from '../components/EducationInfo/EducationInfo';
import SpouseEducation from '../components/EducationInfo/SpouseEducation';
import FinancialInfo from '../components/FinancialInfo/FinancialInfo';
import SpouseIncome from '../components/FinancialInfo/SpouseIncome';
import ExpenseInfo from '../components/FinancialInfo/ExpenseInfo'
import HumanCapitalLanding from '../views/HumanCapitalLanding';
import ProfileBuilderLanding from '../views/ProfileBuilderLanding';
import HumanCapital from '../components/HumanCapital/HumanCapital';
import SpouseHumanCapital from '../components/HumanCapital/SpouseHumanCapital';
import HCEducation from '../components/HumanCapital/HCEducation';
import SpouseHCEducation from '../components/HumanCapital/SpouseHCEducation';
import HCLanguages from '../components/HumanCapital/HCLanguages';
import SpouseHCLanguages from '../components/HumanCapital/SpouseHCLanguages';
import HCProactiveness from '../components/HumanCapital/HCProactiveness';
import SpouseHCProactiveness from '../components/HumanCapital/SpouseHCProactiveness';
import DebtsInfo from '../components/FinancialInfo/DebtsInfo';

const MainContainer = styled(Container)`
    padding-top: 4.82rem !important;
    overflow-y: auto;
    @media (max-width: 576px) {
        padding: 0 24px;
    }
`;
const WelcomeLanding = styled(Container)`
    padding-top: 4.82rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;
    @media (max-width: 576px) {
        padding: 0 24px;
    }
`;

interface MainContentProps {
    nextStep: () => void;
    prevStep: () => void;
    currentStep: number;
}

const expenseCategories = [
    { key: 'groceries', label: 'Groceries' },
    { key: 'hoaDues', label: 'HOA dues' },
    { key: 'propertyTaxes', label: 'Property taxes' },
    { key: 'rent', label: 'Rent' },
    { key: 'clothing', label: 'Clothing' },
    { key: 'fuel', label: 'Fuel' },
    { key: 'power', label: 'Power' },
    { key: 'phone', label: 'Phone' },
    { key: 'water', label: 'Water' },
];

function MainContent({ nextStep, prevStep, currentStep }: MainContentProps) {

    const mainContainerRef = useRef<HTMLDivElement | null>(null);
    const { toggleFlyout } = useFlyout();

    const [formValues, setFormValues] = useState({
        dateOfBirth: undefined,
        zipCode: '',
        gender: '',
        isMarried: '',
        isSpouseMember: '',
        spouseFirstName: '',
        spouseLastName: '',
        spouseGender: '',
        spouseDateOfBirth: undefined,
        hasChildren: '',
        children: [{ name: '', dob: undefined }],
        degree: '',
        education: [
            { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
        ],
        spouseDegree: '',
        spouseEducation: [
            { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
        ],
        monthlySalary: '',
        annualSalary: '',
        additionalIncome: [{
            incomeType: '',
            monthlySalary: '',
            annualSalary: '',
        }],
        spouseMonthlySalary: '',
        spouseAnnualSalary: '',
        spouseAdditionalIncome: [{
            incomeType: '',
            monthlySalary: '',
            annualSalary: '',
        }],
        householdExpenses: expenseCategories.map(category => ({
            category: category.label,
            monthlyExpense: '',
            annualExpense: '',
        })),
        customExpense: [{
            expenseType: '',
            monthlyExpense: '',
            annualExpense: ''
        }],
        humanCapital: [{
            profession: '',
            requireLicense: '',
            passExam: '',
            haveDesignation: '',
            passDesignationExam: '',
            memberOrganization: '',
            collegeAthlete: '',
            runMarathon: '',
            servedMilitary: '',
        }],
        spouseHumanCapital: [{
            profession: '',
            requireLicense: '',
            passExam: '',
            haveDesignation: '',
            passDesignationExam: '',
            memberOrganization: '',
            collegeAthlete: '',
            runMarathon: '',
            servedMilitary: '',
        }],
        wasApScholar: '',
        graduatedFromIbProgram: '',
        offeredMeritOrAcademicScholarship: '',
        offeredFullTuitionScholarship: '',
        spousewasApScholar: '',
        spousegraduatedFromIbProgram: '',
        spouseofferedMeritOrAcademicScholarship: '',
        spouseofferedFullTuitionScholarship: '',
        otherLanguage: '',
        multipleLanguages: '',
        languages: [{
            langName: '',
            speakingLevel: '',
            readingLevel: '',
            writingLevel: '',
        }],
        spouseOtherLanguage: '',
        spouseMultipleLanguages: '',
        spouseLanguages: [{
            langName: '',
            speakingLevel: '',
            readingLevel: '',
            writingLevel: '',
        }],
        useFinancialSoftware: '',
        delinquentLoan: '',
        spouseUseFinancialSoftware: '',
        spouseDelinquentLoan: '',
        debtAccounts: [{
            uniqueId: '',
            accountType: '',
            owner: '',
            planOptions: '',
            financeInst: '',
            accountName: '',
            balance: '',
            payOffMonthly: false,
            interestRate: '',
            minPayment: '',
            isResponsible: '',
            loanInfoType: '',
            loanDetails: [{
                currentBalance: '',
                interestRate: '',
            }],
            estMarkeValue: '',
        }],
    });

    const onNext = (newValues: any) => {
        setFormValues(newValues);
        if (mainContainerRef.current) {
            mainContainerRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
        nextStep();
    };

    const onNextLanding = () => {
        if (mainContainerRef.current) {
            mainContainerRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
        }
        nextStep();
    }

    return (
        <EuiPageBody>
            {currentStep === 0 && (
                <WelcomeLanding fluid ref={mainContainerRef}>
                    <PersonalInfoLanding onNextLanding={onNextLanding} />
                </WelcomeLanding>
            )}
            {currentStep === 1 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <PersonalInfo initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 2 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <EducationInfo initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 3 && formValues.isMarried === 'MaritalStatus-Yes' && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseEducation initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 4 && (
                <WelcomeLanding fluid ref={mainContainerRef}>
                    <FinancialInfoLanding nextStep={nextStep} prevStep={prevStep} />
                </WelcomeLanding>
            )}
            {currentStep === 5 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <FinancialInfo initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 6 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseIncome initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 7 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <ExpenseInfo initialValues={formValues} onNext={onNext} prevStep={prevStep} expenseCategories={expenseCategories} />
                </MainContainer>
            )}
            {currentStep === 8 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <DebtsInfo initialValues={formValues} debtAccounts={formValues.debtAccounts} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 9 && (
                <WelcomeLanding fluid ref={mainContainerRef}>
                    <HumanCapitalLanding nextStep={nextStep} prevStep={prevStep} />
                </WelcomeLanding>
            )}
            {currentStep === 10 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <HumanCapital initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 11 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseHumanCapital initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 12 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <HCEducation initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 13 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseHCEducation initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 14 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <HCLanguages initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 15 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseHCLanguages initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 16 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <HCProactiveness initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 17 && (
                <MainContainer fluid ref={mainContainerRef}>
                    <SpouseHCProactiveness initialValues={formValues} onNext={onNext} prevStep={prevStep} />
                </MainContainer>
            )}
            {currentStep === 18 && (
                <WelcomeLanding fluid ref={mainContainerRef}>
                    <ProfileBuilderLanding />
                </WelcomeLanding>
            )}
        </EuiPageBody>
    );
};

export default MainContent;
