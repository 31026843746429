import { FieldArray, FormikErrors, FormikHelpers } from 'formik';
import { EuiFormRow, EuiFlexItem, EuiFlexGroup, EuiText, EuiFormControlLayout, EuiComboBox, EuiFieldText } from '@elastic/eui';
import { StyledSpacer, StyledEuiLink, StyledSpan, StyledEuiHorizontalRule, StyledEuiButton, StyledEuiTitle } from '../Global/StyledComponents';
import { Global, css } from '@emotion/react';
import { handleCurrencyBlur, handleAnnualSalaryUpdate } from '../../utils/currencyFields';
import { INCOME_TYPE_OPTIONS } from '../../constants/formOptions';

const globalStyles = css`
    @media (max-width: 768px) {
        .mobile-field-row {
            flex-wrap: nowrap !important;
        }
    }
`;

type IncomeDetails = {
    incomeType: string,
    monthlySalary: string,
    annualSalary: string,
}[];

interface IncomeInfoProps {
    fieldName: 'additionalIncome' | 'spouseAdditionalIncome';
    spouseFirstName?: string;
    values: {
        additionalIncome?: IncomeDetails;
        spouseAdditionalIncome?: IncomeDetails;
    };
    errors: FormikErrors<{
        additionalIncome?: IncomeDetails;
        spouseAdditionalIncome?: IncomeDetails;
    }>;
    setFieldValue: FormikHelpers<any>['setFieldValue'];
    submitCount: number;
}

function AdditionalIncome({ values, setFieldValue, submitCount, fieldName, spouseFirstName }: IncomeInfoProps) {

    return (
        <>
            <Global styles={globalStyles} />
            <StyledEuiTitle size="xs">
                <h3>Add Income</h3>
            </StyledEuiTitle>

            <StyledSpacer size="12px" />

            <EuiText size='m'>
                <p>Does {spouseFirstName || "Chris"} have other sources of income? Include bonuses, commissions, and side hustles.</p>
            </EuiText>

            <StyledSpacer size="32px" />

            <StyledEuiHorizontalRule maxWidth="428px" />

            <StyledSpacer size="32px" />

            <FieldArray name={fieldName}>
                {arrayHelpers => (
                    <>
                        {values[fieldName]?.map((fieldItem, index) => (
                            <div key={index}>

                                <EuiFormRow
                                    label={<StyledSpan>Type</StyledSpan>}
                                    className='input-size'
                                // isInvalid={submitCount > 0 && !!(errors[fieldName]?.[index] as any)?.major}
                                >
                                    <EuiFormControlLayout>
                                        <EuiComboBox
                                            rowHeight={40}
                                            isClearable={false}
                                            options={INCOME_TYPE_OPTIONS.map(type => ({ label: type.value }))}
                                            selectedOptions={fieldItem.incomeType ? [{ label: fieldItem.incomeType }] : []}
                                            onChange={options => {
                                                const selectedIncomeType = options.length ? options[0].label : '';
                                                setFieldValue(`${fieldName}[${index}].incomeType`, selectedIncomeType);
                                            }}
                                            singleSelection={{ asPlainText: true }}
                                        />
                                    </EuiFormControlLayout>

                                </EuiFormRow>

                                <StyledSpacer size="32px" />

                                <EuiFlexGroup style={{ maxWidth: 428 }} className='mobile-field-row'>
                                    <EuiFlexItem>
                                        <EuiFormRow label="Monthly">
                                            <EuiFieldText
                                                value={fieldItem.monthlySalary}
                                                onChange={e => {
                                                    setFieldValue(`${fieldName}[${index}].monthlySalary`, e.target.value);
                                                    handleAnnualSalaryUpdate(e.target.value, `${fieldName}[${index}].annualSalary`, setFieldValue);
                                                }}
                                                onBlur={(e) => handleCurrencyBlur(`${fieldName}[${index}].monthlySalary`, setFieldValue)(e)}
                                                min={0}
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                    <EuiFlexItem>
                                        <EuiFormRow label="Annual">
                                            <EuiFieldText
                                                value={fieldItem.annualSalary}
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>
                                </EuiFlexGroup>

                                {index > 0 && (
                                    <>
                                        <StyledSpacer size="24px" />

                                        <StyledEuiLink color="primary" onClick={() => arrayHelpers.remove(index)}>
                                            Delete
                                        </StyledEuiLink>
                                    </>
                                )}

                                <StyledSpacer size="32px" />

                                <StyledEuiHorizontalRule maxWidth="428px" />

                                <StyledSpacer size="32px" />
                            </div>
                        ))}
                        <StyledEuiButton color='text' iconType="plus" onClick={() => arrayHelpers.push({
                            incomeType: '',
                            monthlySalary: '',
                            annualSalary: '',
                        })}>
                            Add more income
                        </StyledEuiButton>
                    </>
                )}
            </FieldArray>

        </>

    )
}

export default AdditionalIncome;