import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow, EuiButton, EuiRadioGroup, EuiFieldText } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton } from '../Global/StyledComponents';
import { OTHER_LANGUAGES, MULTIPLE_LANGUAGES, SPEAKING_LEVEL, WRITING_LEVEL, READING_LEVEL, SPEAKING_LEVEL2, READING_LEVEL2, WRITING_LEVEL2 } from '../../constants/formOptions';

interface HCLanguagesFormValues {
    otherLanguage: string,
    multipleLanguages: string,
    languages: {
        langName: string,
        speakingLevel: string,
        readingLevel: string,
        writingLevel: string,
    }[],
}

interface HCLanguageProps {
    initialValues: HCLanguagesFormValues;
    onNext: (values: HCLanguagesFormValues) => void;
    prevStep: () => void;
}

function HCLanguages({ initialValues, onNext, prevStep }: HCLanguageProps) {

    const handleSubmit = (formValues: HCLanguagesFormValues) => {
        onNext(formValues);
    }

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    <h1>Your Languages</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    <p>The number of languages you speak lowers the risk to your income and increases your income potential.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    label="Do you speak or read other languages?"
                                >
                                    <EuiRadioGroup
                                        name="otherLanguage"
                                        options={OTHER_LANGUAGES}
                                        idSelected={values.otherLanguage}
                                        onChange={value => setFieldValue('otherLanguage', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                {values.otherLanguage === 'otherLanguage-Yes' && (
                                    <>
                                        <EuiFormRow
                                            label="Do you speak 2 or more languages?"
                                        >
                                            <EuiRadioGroup
                                                name="multipleLanguages"
                                                options={MULTIPLE_LANGUAGES}
                                                idSelected={values.multipleLanguages}
                                                onChange={value => setFieldValue('multipleLanguages', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            className='input-size'
                                            label="Language #1"
                                        >
                                            <EuiFieldText
                                                name="languages[0].langName"
                                                value={values.languages[0].langName}
                                                onChange={e => setFieldValue('languages[0].langName', e.target.value)}
                                            />

                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Speaking"
                                        >
                                            <EuiRadioGroup
                                                name="languages[0].speakingLevel"
                                                idSelected={values.languages[0].speakingLevel}
                                                options={SPEAKING_LEVEL}
                                                onChange={value => setFieldValue('languages[0].speakingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Reading"
                                        >
                                            <EuiRadioGroup
                                                name="languages[0].readingLevel"
                                                idSelected={values.languages[0].readingLevel}
                                                options={READING_LEVEL}
                                                onChange={value => setFieldValue('languages[0].readingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Writing"
                                        >
                                            <EuiRadioGroup
                                                name="languages[0].writingLevel"
                                                idSelected={values.languages[0].writingLevel}
                                                options={WRITING_LEVEL}
                                                onChange={value => setFieldValue('languages[0].writingLevel', value)}
                                            />
                                        </EuiFormRow>
                                    </>
                                )}

                                {values.multipleLanguages === 'multipleLanguages-Yes' && (
                                    <>
                                        <StyledSpacer size='32px' />
                                        
                                        <EuiFormRow
                                            className='input-size'
                                            label="Language #2"
                                        >
                                            <EuiFieldText
                                                name="languages[1].langName"
                                                value={values.languages[1]?.langName || ''}
                                                onChange={e => setFieldValue('languages[1].langName', e.target.value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Speaking">
                                            <EuiRadioGroup
                                                name="languages[1].speakingLevel"
                                                idSelected={values.languages[1]?.speakingLevel}
                                                options={SPEAKING_LEVEL2}
                                                onChange={value => setFieldValue('languages[1].speakingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Reading">
                                            <EuiRadioGroup
                                                name="languages[1].readingLevel"
                                                idSelected={values.languages[1]?.readingLevel}
                                                options={READING_LEVEL2}
                                                onChange={value => setFieldValue('languages[1].readingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Writing">
                                            <EuiRadioGroup
                                                name="languages[1].writingLevel"
                                                idSelected={values.languages[1]?.writingLevel}
                                                options={WRITING_LEVEL2}
                                                onChange={value => setFieldValue('languages[1].writingLevel', value)}
                                            />
                                        </EuiFormRow>
                                    </>
                                )}

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    className='btn-text'
                                >
                                    Next
                                </EuiButton>

                                <StyledEuiButton color='text'>
                                    Finish
                                </StyledEuiButton>

                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default HCLanguages;