import { FieldArray, FormikHelpers } from 'formik';
import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiFieldText, EuiComboBox } from '@elastic/eui';
import { StyledSpacer, StyledEuiHorizontalRule, StyledEuiIcon, StyledEuiButton, StyledEuiLink } from '../Global/StyledComponents';
import { handleCurrencyBlur, handleAnnualSalaryUpdate } from '../../utils/currencyFields';
import { allExpenseOptions } from '../../constants/formOptions';
import { Global, css } from '@emotion/react';
import useWindowSize from '../../hooks/useWindowSize';

const globalStyles = css`
    .euiComboBoxOptionsList__rowWrap div {
        width: 100% !important;
    }
    .euiComboBoxOptionsList div.euiFlexItem {
        width: 1rem !important;
    }
    div.euiComboBoxTitle {
        font-size: 16px;
        font-weight: 600;
    }
    @media (min-width: 769px) {
        .euiComboBoxOptionsList {
            width: 300px !important;
        }
    }
    @media (max-width: 769px) {
        .mobile-field-row {
            flex-wrap: nowrap !important;
        }
        .mobile-type {
            max-width: 100% !important;
        }
    }
`;

type CustomExpenseDetails = {
    expenseType: string;
    monthlyExpense: string;
    annualExpense: string;
}[];

interface CustomExpenseInfoProps {
    values: {
        customExpense: CustomExpenseDetails;
    };
    setFieldValue: FormikHelpers<any>['setFieldValue'];
}

function CustomExpense({ values, setFieldValue }: CustomExpenseInfoProps) {
    const { width } = useWindowSize();
    const isMobileView = width <= 769;

    const onCreateOption = (searchValue: string, index: number) => {
        setFieldValue(`customExpense[${index}].expenseType`, searchValue);
    };

    return (
        <>
            <Global styles={globalStyles} />
            <FieldArray name="customExpense">

                {arrayHelpers => (

                    <>
                        {values.customExpense.map((customExpense, index) => (

                            <div key={index}>

                                {index > 0 && <StyledSpacer size='32px' />}

                                <EuiFlexGroup justifyContent="spaceBetween" alignItems="flexEnd">

                                    <EuiFlexItem grow={2} className='mobile-type'>
                                        <EuiFormRow label="Type">
                                            <EuiComboBox
                                                fullWidth={true}
                                                aria-label="Add custom expense"
                                                isClearable={false}
                                                singleSelection={{ asPlainText: true }}
                                                options={allExpenseOptions}
                                                selectedOptions={[{ label: customExpense.expenseType }]}
                                                onChange={(selectedOptions) => {
                                                    if (selectedOptions.length > 0) {
                                                        setFieldValue(`customExpense[${index}].expenseType`, selectedOptions[0].label);
                                                    } else {
                                                        setFieldValue(`customExpense[${index}].expenseType`, '');
                                                    }
                                                }}
                                                onCreateOption={(searchValue) =>
                                                    onCreateOption(searchValue, index)
                                                }
                                            />
                                        </EuiFormRow>
                                    </EuiFlexItem>

                                    {isMobileView ? (
                                        <EuiFlexGroup className='mobile-field-row'>
                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow label="Monthly">
                                                    <EuiFieldText
                                                        name={`customExpense[${index}].monthlyExpense`}
                                                        value={customExpense.monthlyExpense}
                                                        onChange={(e) => {
                                                            setFieldValue(`customExpense[${index}].monthlyExpense`, e.target.value);
                                                            handleAnnualSalaryUpdate(e.target.value, `customExpense[${index}].annualExpense`, setFieldValue);
                                                        }}
                                                        onBlur={(e) => handleCurrencyBlur(`customExpense[${index}].monthlyExpense`, setFieldValue)(e)}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>

                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow label="Annual">
                                                    <EuiFieldText
                                                        name={`customExpense[${index}].annualExpense`}
                                                        value={customExpense.annualExpense}
                                                        readOnly
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    ) : (
                                        <>
                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow>
                                                    <EuiFieldText
                                                        name={`customExpense[${index}].monthlyExpense`}
                                                        value={customExpense.monthlyExpense}
                                                        onChange={(e) => {
                                                            setFieldValue(`customExpense[${index}].monthlyExpense`, e.target.value);
                                                            handleAnnualSalaryUpdate(e.target.value, `customExpense[${index}].annualExpense`, setFieldValue);
                                                        }}
                                                        onBlur={(e) => handleCurrencyBlur(`customExpense[${index}].monthlyExpense`, setFieldValue)(e)}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>

                                            <EuiFlexItem grow={4} style={{ maxWidth: 200 }}>
                                                <EuiFormRow>
                                                    <EuiFieldText
                                                        name={`customExpense[${index}].annualExpense`}
                                                        value={customExpense.annualExpense}
                                                    />
                                                </EuiFormRow>
                                            </EuiFlexItem>
                                        </>
                                    )}

                                    {!isMobileView && (
                                        <EuiFlexItem grow={false}>
                                            <EuiFormRow hasEmptyLabelSpace>
                                                <StyledEuiIcon type="cross" onClick={() => arrayHelpers.remove(index)} />
                                            </EuiFormRow>
                                        </EuiFlexItem>
                                    )}

                                </EuiFlexGroup>

                                {isMobileView && (
                                    <>
                                        <StyledSpacer size="24px" />
                                        <StyledEuiLink color="primary" onClick={() => arrayHelpers.remove(index)}>
                                            Delete
                                        </StyledEuiLink>
                                    </>
                                )}

                                <StyledSpacer size='32px' />

                                <StyledEuiHorizontalRule />

                            </div>
                        ))}
                        <StyledSpacer size='32px' />

                        <StyledEuiButton
                            color='text'
                            iconType="plus"
                            onClick={() => arrayHelpers.push({ expenseType: '', monthlyExpense: '', annualExpense: '' })}
                        >
                            Add household expense
                        </StyledEuiButton>

                    </>
                )}
            </FieldArray>

        </>


    );
};

export default CustomExpense;
