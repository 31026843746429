import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateDebtAccounts, addDebtAccount } from '../../redux/actions/actions';
import { RootState } from '../../redux/store/store';
import { Formik, Form, FieldArray } from 'formik';
// import FormError from '../Global/FormError';
import { EuiComboBox, EuiFormRow, EuiFieldText, EuiCheckbox, EuiRadioGroup, EuiSuperSelect, EuiButton } from '@elastic/eui';
import { StyledSpacer, StyledSpan, StyledBsAsterisk, StyledEuiLink, StyledEuiButton } from '../Global/StyledComponents';
import { allDebtAccountTypes, RESPONSIBLE_FOR_LOAN, LOAN_PLAN_OPTIONS } from '../../constants/formOptions';
// import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import CompleteLoanDetails from './CompleteLoanDetails';
import { handleInputChange, handleCurrencyBlur, handleRoundedCurrencyBlur, handlePercentageBlur } from '../../utils/currencyFields';

export interface ManualAccountFormValues {
    spouseFirstName: string;
    isMarried: string;
    education: {
        gradYear: string;
    }[];
    spouseEducation: {
        gradYear: string;
    }[];
    debtAccounts: {
        uniqueId: string | null;
        accountType: string;
        owner: string;
        planOptions: string;
        financeInst: string;
        accountName: string;
        balance: string;
        payOffMonthly: boolean;
        interestRate: string;
        minPayment: string;
        isResponsible: string;
        loanInfoType: string;
        estMarkeValue: string;
        loanDetails: {
            currentBalance: string;
            interestRate: string;
        }[];
    }[];
};

interface ManualAccountFormProps {
    initialValues: ManualAccountFormValues;
    onDebtAccountAdded: (debtAccountData: ManualAccountFormValues['debtAccounts'][0]) => void;
    onFormSubmit?: (submitFunction: () => void) => void;
}

function ManualAccountForm({ initialValues, onDebtAccountAdded, onFormSubmit }: ManualAccountFormProps) {

    const handleAddAccount = (values: ManualAccountFormValues, newAccountIndex: number) => {
        const newAccount = values.debtAccounts[newAccountIndex];
        onDebtAccountAdded(newAccount);
    };

    console.log('ManualAccountForm', onDebtAccountAdded);

    // const formikConfig = useValidationForm(FormType.PersonalInfo, handleAddAccount);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleAddAccount(values, values.debtAccounts.length - 1)}
        // validationSchema={formikConfig.validationSchema}
        >
            {({
                setFieldValue,
                values,
                submitForm,
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValid,
                submitCount,
                validateField
            }) => {

                const currentAccount = values.debtAccounts[0];

                const selectedAccountType = currentAccount.accountType;

                const selectedLoanPlan = values.debtAccounts[0].planOptions

                const currentYear = new Date().getFullYear();

                const loanPlanBehaviors: { [key: string]: () => JSX.Element | null } = {
                    "Standard": () => {
                        return (
                            <>
                                <CompleteLoanDetails />
                            </>
                        );
                    },
                    "Extended": () => {/* behavior for Extended */ return null; },
                    "IBR for new borrowers": () => {
                        return (
                            <CompleteLoanDetails />
                        );
                    },
                    "I'm not sure": () => {
                        const accountIndex = 0;
                        return (
                            <>
                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    label="Balance"
                                    className='input-size'
                                >
                                    <EuiFieldText
                                        name={`debtAccounts[${accountIndex}].balance`}
                                        value={values.debtAccounts[accountIndex].balance}
                                        onChange={handleInputChange(`debtAccounts[${accountIndex}].balance`, setFieldValue)}
                                        onBlur={handleCurrencyBlur(`debtAccounts[${accountIndex}].balance`, setFieldValue)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    label="Interest Rate"
                                    className='input-size'
                                >
                                    <EuiFieldText
                                        name={`debtAccounts[${accountIndex}]interestRate`}
                                        value={values.debtAccounts[accountIndex].interestRate}
                                        onChange={handleInputChange(`debtAccounts[${accountIndex}].interestRate`, setFieldValue)}
                                        onBlur={handlePercentageBlur(`debtAccounts[${accountIndex}].interestRate`, setFieldValue)}
                                    />

                                </EuiFormRow>
                            </>
                        );
                    }
                };

                const currentLoanPlanOption = LOAN_PLAN_OPTIONS.find(option => option.value === selectedLoanPlan);

                const currentBehavior = currentLoanPlanOption ? loanPlanBehaviors[currentLoanPlanOption.inputDisplay] : null;

                const hasGraduated = (values.education && values.education.some(edu => edu.gradYear && parseInt(edu.gradYear) < currentYear)) ||
                    (values.spouseEducation && values.spouseEducation.some(edu => edu.gradYear && parseInt(edu.gradYear) < currentYear));

                const helpTextForSelectedPlan = currentLoanPlanOption && currentLoanPlanOption.inputDisplay === "IBR for new borrowers"
                    ? 'Graduated plan is assumed to be amortized on a straight line basis.'
                    : '';

                return (
                    <Form>
                        <FieldArray
                            name='debtAccounts'
                            render={arrayHelpers => (
                                <>
                                    {values.debtAccounts?.map((account, index) => (
                                        <div key={`debtAccount-${index}-${account.uniqueId}`}>

                                            <StyledSpacer size='32px' />

                                            <EuiFormRow label={<StyledSpan>Account Type<StyledBsAsterisk /></StyledSpan>}>
                                                <EuiComboBox
                                                    fullWidth={true}
                                                    aria-label="Select account type"
                                                    isClearable={false}
                                                    singleSelection={{ asPlainText: true }}
                                                    options={allDebtAccountTypes}
                                                    selectedOptions={account.accountType ? [{ label: account.accountType }] : []}
                                                    onChange={(selected) => {
                                                        const selectedType = selected.length > 0 ? selected[0].label : '';
                                                        setFieldValue(`debtAccounts[${index}].accountType`, selectedType);
                                                    }}
                                                />
                                            </EuiFormRow>

                                            {values.isMarried === 'MaritalStatus-Yes' && (
                                                <>
                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow label="Owner">
                                                        <EuiComboBox
                                                            fullWidth={true}
                                                            aria-label="Account owner"
                                                            isClearable={false}
                                                            singleSelection={{ asPlainText: true }}
                                                            options={[{ label: 'Sarah' }, { label: 'Kevin' }]}
                                                            selectedOptions={values.debtAccounts.map(account => ({ label: account.owner }))}
                                                        />
                                                    </EuiFormRow>
                                                </>
                                            )}

                                            {selectedAccountType === "Federal Student Loan" && hasGraduated && (
                                                <>
                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Balance"
                                                        // isInvalid={submitCount > 0 && !!errors.zipCode}
                                                        // error={errors.zipCode ? [errors.zipCode] : undefined}
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name={`debtAccounts[${index}].balance`}
                                                            value={values.debtAccounts[index].balance}
                                                            onChange={handleInputChange(`debtAccounts[${index}].balance`, setFieldValue)}
                                                            onBlur={handleCurrencyBlur(`debtAccounts[${index}].balance`, setFieldValue)}
                                                        />

                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label={<StyledSpan>Interest Rate</StyledSpan>}
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name="interestRate"
                                                            value={values.debtAccounts[0].interestRate}
                                                            onChange={handleInputChange('debtAccounts[0].interestRate', setFieldValue)}
                                                            onBlur={handlePercentageBlur('debtAccounts[0].interestRate', setFieldValue)}
                                                        />
                                                    </EuiFormRow>
                                                </>
                                            )}

                                            {selectedAccountType === "Federal Student Loan" && !hasGraduated && (
                                                <>
                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Current Federal Student Loan Plan"
                                                        helpText={helpTextForSelectedPlan}
                                                    >
                                                        <EuiSuperSelect
                                                            options={LOAN_PLAN_OPTIONS}
                                                            valueOfSelected={selectedLoanPlan || ''}
                                                            onChange={(value) => {
                                                                setFieldValue(`debtAccounts[0].planOptions`, value);
                                                            }}
                                                        />
                                                    </EuiFormRow>

                                                    {currentBehavior && currentBehavior()}
                                                </>
                                            )}

                                            {selectedAccountType === "Credit Card" && (
                                                <>
                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label={<StyledSpan>Financial Institution<StyledBsAsterisk /></StyledSpan>}
                                                        // isInvalid={submitCount > 0 && !!errors.zipCode}
                                                        // error={errors.zipCode ? [errors.zipCode] : undefined}
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name="debtAccounts[0].financeInst"
                                                            value={values.debtAccounts[0].financeInst}
                                                            onChange={e => setFieldValue('debtAccounts[0].financeInst', e.target.value)}
                                                        />
                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Account Name"
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name="debtAccounts[0].accountName"
                                                            value={values.debtAccounts[0].accountName}
                                                            onChange={e => setFieldValue('debtAccounts[0].accountName', e.target.value)}
                                                        />
                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Balance"
                                                        className='input-size'
                                                    >
                                                        <>
                                                            <EuiFieldText
                                                                name={`debtAccounts[${index}].balance`}
                                                                value={values.debtAccounts[index].balance}
                                                                onChange={handleInputChange(`debtAccounts[${index}].balance`, setFieldValue)}
                                                                onBlur={handleCurrencyBlur(`debtAccounts[${index}].balance`, setFieldValue)}
                                                            />

                                                            <StyledSpacer size='32px' />

                                                            <EuiCheckbox
                                                                id="payOffMonthlyCheckbox"
                                                                label="I pay this off monthly"
                                                                checked={values.debtAccounts[0].payOffMonthly}
                                                                onChange={e => setFieldValue('debtAccounts[0].payOffMonthly', e.target.checked)}
                                                            />


                                                        </>
                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Interest Rate"
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name="interestRate"
                                                            value={values.debtAccounts[0].interestRate}
                                                            onChange={handleInputChange('debtAccounts[0].interestRate', setFieldValue)}
                                                            onBlur={handlePercentageBlur('debtAccounts[0].interestRate', setFieldValue)}
                                                        />
                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Minimum Payment"
                                                        className='input-size'
                                                    >
                                                        <EuiFieldText
                                                            name="minPayment"
                                                            value={values.debtAccounts[0].minPayment}
                                                            onChange={handleInputChange('debtAccounts[0].minPayment', setFieldValue)}
                                                            onBlur={handleCurrencyBlur('debtAccounts[0].minPayment', setFieldValue)}
                                                        />
                                                    </EuiFormRow>
                                                </>
                                            )}

                                            {selectedAccountType === "Parent PLUS loan" && (
                                                <>
                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Current Federal Student Loan Plan"
                                                        helpText={helpTextForSelectedPlan}
                                                    >
                                                        <EuiSuperSelect
                                                            options={LOAN_PLAN_OPTIONS}
                                                            valueOfSelected={selectedLoanPlan || ''}
                                                            onChange={(value) => {
                                                                setFieldValue(`debtAccounts[0].planOptions`, value);
                                                            }}
                                                        />
                                                    </EuiFormRow>

                                                    <StyledSpacer size='32px' />

                                                    <EuiFormRow
                                                        label="Are you responsible for paying this loan back?"
                                                    >
                                                        <EuiRadioGroup
                                                            name="isResponsible"
                                                            options={RESPONSIBLE_FOR_LOAN}
                                                            idSelected={values.debtAccounts[0].isResponsible}
                                                            onChange={value => setFieldValue('debtAccounts[0].isResponsible', value)}
                                                        />

                                                    </EuiFormRow>

                                                    <CompleteLoanDetails />
                                                </>
                                            )}

                                            {
                                                (selectedAccountType === "Auto loan" ||
                                                    selectedAccountType === "Home mortgage" ||
                                                    selectedAccountType === "Rental property mortgage") && (
                                                    <>
                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label={<StyledSpan>Financial Institution<StyledBsAsterisk /></StyledSpan>}
                                                            // isInvalid={submitCount > 0 && !!errors.zipCode}
                                                            // error={errors.zipCode ? [errors.zipCode] : undefined}
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name="debtAccounts[0].financeInst"
                                                                value={values.debtAccounts[0].financeInst}
                                                                onChange={e => setFieldValue('debtAccounts[0].financeInst', e.target.value)}
                                                            />
                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label="Account Name"
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name="debtAccounts[0].accountName"
                                                                value={values.debtAccounts[0].accountName}
                                                                onChange={e => setFieldValue('debtAccounts[0].accountName', e.target.value)}
                                                            />
                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label="Balance"
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name={`debtAccounts[${index}].balance`}
                                                                value={values.debtAccounts[index].balance}
                                                                onChange={handleInputChange(`debtAccounts[${index}].balance`, setFieldValue)}
                                                                onBlur={handleCurrencyBlur(`debtAccounts[${index}].balance`, setFieldValue)}
                                                            />

                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label="Interest Rate"
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name="interestRate"
                                                                value={values.debtAccounts[0].interestRate}
                                                                onChange={handleInputChange('debtAccounts[0].interestRate', setFieldValue)}
                                                                onBlur={handlePercentageBlur('debtAccounts[0].interestRate', setFieldValue)}
                                                            />
                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label="Minimum Payment"
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name="minPayment"
                                                                value={values.debtAccounts[0].minPayment}
                                                                onChange={handleInputChange('debtAccounts[0].minPayment', setFieldValue)}
                                                                onBlur={handleCurrencyBlur('debtAccounts[0].minPayment', setFieldValue)}
                                                            />
                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                        <EuiFormRow
                                                            label={<StyledSpan>Estimated Market Value of Property</StyledSpan>}
                                                            className='input-size'
                                                        >
                                                            <EuiFieldText
                                                                name="estMarketValue"
                                                                value={values.debtAccounts[0].estMarkeValue}
                                                                onChange={handleInputChange('debtAccounts[0].estMarkeValue', setFieldValue)}
                                                                onBlur={handleRoundedCurrencyBlur('debtAccounts[0].estMarkeValue', setFieldValue)}
                                                            />
                                                        </EuiFormRow>

                                                        <StyledSpacer size='32px' />

                                                    </>
                                                )
                                            }

                                            {/* <FormError names={[`${fieldName}[${index}].major`]} /> */}

                                            {index === values.debtAccounts.length - 1 && (
                                                <>
                                                    <StyledSpacer size='32px' />
                                                    <StyledEuiButton color='text' iconType="plus" onClick={() => arrayHelpers.push({
                                                        uniqueId: Date.now().toString(),
                                                        accountType: '',
                                                        owner: '',
                                                        planOptions: '',
                                                        financeInst: '',
                                                        accountName: '',
                                                        balance: '',
                                                        payOffMonthly: false,
                                                        interestRate: '',
                                                        minPayment: '',
                                                        isResponsible: '',
                                                        loanInfoType: '',
                                                        estMarkeValue: '',
                                                        loanDetails: [{ currentBalance: '', interestRate: '' }],
                                                    })}>
                                                        Add Debt Account
                                                    </StyledEuiButton>
                                                </>
                                            )}
                                        </div>

                                    ))}
                                </>
                            )}
                        />

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default ManualAccountForm;
