import { Formik, Form } from 'formik';
import FormError from '../Global/FormError';
import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import {
    EuiFormRow, EuiRadioGroup,
    EuiButton, EuiButtonEmpty, EuiTitle, EuiText
} from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { DEGREE_OPTIONS } from '../../constants/formOptions';
import { StyledSpacer, StyledFormRow, LabelAsterisk, LabelStyle } from '../Global/StyledComponents';
import DegreeInfo from './DegreeInfo';

interface SpouseDegreeFormValues {
    spouseFirstName?: string;
    spouseGender?: string;
    spouseDegree: string;
    spouseEducation: { university: string, major: string, gradMonth: string, gradYear: string, adv_deg: string }[];
}

interface SpouseDegreeInfoProps {
    initialValues: SpouseDegreeFormValues;
    onNext: (values: SpouseDegreeFormValues) => void;
    prevStep: () => void;
}

function SpouseEducation({ initialValues, onNext, prevStep }: SpouseDegreeInfoProps) {

    const handleSubmit = (formValues: SpouseDegreeFormValues) => {
        onNext(formValues);
    }

    const formikConfig = useValidationForm(FormType.spouseEducation, handleSubmit);

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>

                <StyledSpacer size="32px" />

                <EuiTitle size="l" className='header-font'>
                    <h1>{initialValues.spouseFirstName}'s education</h1>
                </EuiTitle>

                <StyledSpacer size='24px' />

                <EuiText size='m'>
                    <p>
                        {initialValues.spouseFirstName}'s education reduces the risk to{initialValues.spouseGender === 'SpouseMale' ? ' his ' :
                            initialValues.spouseGender === 'SpouseFemale' ? ' her ' : ' their '}
                        income. The risk to{initialValues.spouseGender === 'SpouseMale' ? ' his ' :
                            initialValues.spouseGender === 'SpouseFemale' ? ' her ' : ' their '}
                        income affects our recommendations for student loan repayment plans, investments, and more.
                    </p>
                </EuiText>

                <StyledSpacer size='36px' />

                <Formik
                    initialValues={initialValues}
                    validationSchema={formikConfig.validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <Form>
                            <EuiFormRow
                                label={<LabelStyle>Has {initialValues.spouseFirstName} completed or {
                                    initialValues.spouseGender === 'SpouseMale' ? 'is he' :
                                    initialValues.spouseGender === 'SpouseFemale' ? 'is she' : 'are they'
                                } pursuing a degree?
                                    <LabelAsterisk /></LabelStyle>}
                                isInvalid={submitCount > 0 && !!errors.spouseEducation}
                                className='input-size'
                            >

                                <EuiRadioGroup
                                    name="spouseDegree"
                                    options={DEGREE_OPTIONS}
                                    idSelected={values.spouseDegree}
                                    onChange={value => setFieldValue('spouseDegree', value)}
                                />

                            </EuiFormRow>

                            <FormError names={["spouseDegree"]} />

                            {values.spouseDegree === 'Yes' && (
                                <DegreeInfo
                                    fieldName="spouseEducation"
                                    spouseFirstName={values.spouseFirstName}
                                    values={values}
                                    errors={errors}
                                    setFieldValue={setFieldValue}
                                    submitCount={submitCount}
                                />
                            )}

                            <StyledSpacer size='48px' />

                            <EuiButton
                                type="submit"
                                color='primary'
                                fill
                                isLoading={isSubmitting}
                                disabled={(submitCount > 0 && !isValid) || isSubmitting}
                            >
                                Next
                            </EuiButton>

                            <StyledSpacer size='32px' />

                            {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                        </Form>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default SpouseEducation;