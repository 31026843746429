import React from 'react';
import styled from '@emotion/styled';

interface ProgressBarProps {
    currentStep: number;
    totalSteps: number;
}

const ProgressBarContainer = styled.div`
    margin-top: 4.2rem;
    background-color: #E9EDF3;
    height: 8px;
    width: 100%;
    position: fixed;
    overflow: hidden;
    z-index: 1;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 8px;
        background-color: #ffffff;
    }

    &::before {
        left: calc(33.33% - 4px);
    }

    &::after {
        right: calc(33.33% - 4px);
    }
`;

const Filler = styled.div`
    background-color: #00BFB3;
    height: 100%;
    transition: width 0.2s ease-in;
`;

function ProgressBar ({ currentStep, totalSteps }: ProgressBarProps) {
    
    const fillPercentage = (currentStep / totalSteps) * 100;

    return (
        <ProgressBarContainer>
            <Filler style={{ width: `${fillPercentage}%` }} />
        </ProgressBarContainer>
    );
};

export default ProgressBar;
