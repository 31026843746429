import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow, EuiButton, EuiRadioGroup, EuiFieldText } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton } from '../Global/StyledComponents';
import { SPEAKING_LEVEL2, READING_LEVEL2, WRITING_LEVEL2, SPOUSE_OTHER_LANGUAGES, SPOUSE_MULTIPLE_LANGUAGES, SPOUSE_SPEAKING_LEVEL, SPOUSE_READING_LEVEL, SPOUSE_WRITING_LEVEL } from '../../constants/formOptions';

interface SpouseHCLanguagesFormValues {
    spouseFirstName: string,
    spouseOtherLanguage: string,
    spouseMultipleLanguages: string,
    spouseLanguages: {
        langName: string,
        speakingLevel: string,
        readingLevel: string,
        writingLevel: string,
    }[],
}

interface SpouseHCLanguageProps {
    initialValues: SpouseHCLanguagesFormValues;
    onNext: (values: SpouseHCLanguagesFormValues) => void;
    prevStep: () => void;
}

function SpouseHCLanguages({ initialValues, onNext, prevStep }: SpouseHCLanguageProps) {

    const handleSubmit = (formValues: SpouseHCLanguagesFormValues) => {
        onNext(formValues);
    }

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    {/* <h1>{initialValues.spouseFirstName}'s Income</h1> */}
                    <h1>Tonya's Languages</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    {/* <p>The number of languages {initialValues.spouseFirstName} speaks lowers the risk to your income and increases your income potential.</p> */}
                    <p>The number of languages Tonya speaks lowers the risk to their income and increases their income potential.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    // label={Does {initialValues.spouseFirstName} speak or read other languages?}
                                    label="Does Tonya speak or read other languages?"
                                >
                                    <EuiRadioGroup
                                        name="spouseOtherLanguage"
                                        options={SPOUSE_OTHER_LANGUAGES}
                                        idSelected={values.spouseOtherLanguage}
                                        onChange={value => setFieldValue('spouseOtherLanguage', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                {values.spouseOtherLanguage === 'spouseOtherLanguage-Yes' && (
                                    <>
                                        <EuiFormRow
                                            // label={Does {initialValues.spouseFirstName} speak 2 or more languages?}
                                            label="Does Tonya speak 2 or more languages?"
                                        >
                                            <EuiRadioGroup
                                                name="spouseMultipleLanguages"
                                                options={SPOUSE_MULTIPLE_LANGUAGES}
                                                idSelected={values.spouseMultipleLanguages}
                                                onChange={value => setFieldValue('spouseMultipleLanguages', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            className='input-size'
                                            label="Language #1"
                                        >
                                            <EuiFieldText
                                                name="spouseLanguages[0].langName"
                                                value={values.spouseLanguages[0].langName}
                                                onChange={e => setFieldValue('spouseLanguages[0].langName', e.target.value)}
                                            />

                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Speaking"
                                        >
                                            <EuiRadioGroup
                                                name="spouseLanguages[0].speakingLevel"
                                                idSelected={values.spouseLanguages[0].speakingLevel}
                                                options={SPOUSE_SPEAKING_LEVEL}
                                                onChange={value => setFieldValue('spouseLanguages[0].speakingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Reading"
                                        >
                                            <EuiRadioGroup
                                                name="spouseLanguages[0].readingLevel"
                                                idSelected={values.spouseLanguages[0].readingLevel}
                                                options={SPOUSE_READING_LEVEL}
                                                onChange={value => setFieldValue('spouseLanguages[0].readingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            label="Writing"
                                        >
                                            <EuiRadioGroup
                                                name="spouseLanguages[0].writingLevel"
                                                idSelected={values.spouseLanguages[0].writingLevel}
                                                options={SPOUSE_WRITING_LEVEL}
                                                onChange={value => setFieldValue('spouseLanguages[0].writingLevel', value)}
                                            />
                                        </EuiFormRow>
                                    </>
                                )}

                                {values.spouseMultipleLanguages === 'spouseMultipleLanguages-Yes' && (
                                    <>
                                        <StyledSpacer size='32px' />

                                        <EuiFormRow
                                            className='input-size'
                                            label="Language #2"
                                        >
                                            <EuiFieldText
                                                name="spouseLanguages[1].langName"
                                                value={values.spouseLanguages[1]?.langName || ''}
                                                onChange={e => setFieldValue('spouseLanguages[1].langName', e.target.value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Speaking">
                                            <EuiRadioGroup
                                                name="spouseLanguages[1].speakingLevel"
                                                idSelected={values.spouseLanguages[1]?.speakingLevel}
                                                options={SPEAKING_LEVEL2}
                                                onChange={value => setFieldValue('spouseLanguages[1].speakingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Reading">
                                            <EuiRadioGroup
                                                name="spouseLanguages[1].readingLevel"
                                                idSelected={values.spouseLanguages[1]?.readingLevel}
                                                options={READING_LEVEL2}
                                                onChange={value => setFieldValue('spouseLanguages[1].readingLevel', value)}
                                            />
                                        </EuiFormRow>

                                        <StyledSpacer size='32px' />

                                        <EuiFormRow label="Writing">
                                            <EuiRadioGroup
                                                name="spouseLanguages[1].writingLevel"
                                                idSelected={values.spouseLanguages[1]?.writingLevel}
                                                options={WRITING_LEVEL2}
                                                onChange={value => setFieldValue('spouseLanguages[1].writingLevel', value)}
                                            />
                                        </EuiFormRow>
                                    </>
                                )}

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    className='btn-text'
                                >
                                    Next
                                </EuiButton>

                                <StyledEuiButton color='text'>
                                    Finish
                                </StyledEuiButton>

                                {/* <StyledSpacer size='48px' />

                                <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default SpouseHCLanguages;