import { getIn, Field, FieldProps } from 'formik';
import { BsFillExclamationCircleFill } from "react-icons/bs";
import styled from '@emotion/styled';

const ErrorText = styled.div`
  color: #BD271E;
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;
const StyledIcon = styled(BsFillExclamationCircleFill)`
  width: 20px;
  height: 20px;
`;

const FormError = ({ names }: { names: string[] }) => (
    <Field>
        {({ form: { errors, submitCount } }: FieldProps) => {
            const errorMessage = names.map(name => getIn(errors, name)).find(msg => !!msg);
            return submitCount > 0 && errorMessage ? (
                <ErrorText>
                    <StyledIcon />
                    {errorMessage}
                </ErrorText>
            ) : null;
        }}
    </Field>
);

export default FormError;
