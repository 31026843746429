import React from 'react';
import { EuiFormRow, EuiFieldText, EuiFlexItem } from '@elastic/eui';

export const createExpenseField = (
    name: string,
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void,
    index: number,
    isMobileView: boolean,
    type: "Monthly" | "Annual"
) => {
    const label = isMobileView || index === 0 ? type : "";

    return (
        <EuiFlexItem grow={4} style={{ maxWidth: 200 }} className={isMobileView ? 'mobile-field' : ''}>
            <EuiFormRow label={label}>
                <EuiFieldText
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                />
            </EuiFormRow>
        </EuiFlexItem>
    );
};

