export interface Option {
    id: string;
    label: string;
}

const createOptions = (prefix: string, options: { label: string }[]): Option[] =>
    options.map(option => ({
        id: `${prefix}${option.label}`,
        label: option.label,
    }));

export const GENDER_OPTIONS: Option[] = [
    { id: 'Male', label: 'Male' },
    { id: 'Female', label: 'Female' },
    { id: 'Other', label: 'Other/Prefer not to say' },
];

export const SPOUSE_GENDER_OPTIONS: Option[] = createOptions('Spouse-', [
    { label: 'Male' },
    { label: 'Female' },
    { label: 'Other/Prefer not to say' },
]);

export const MARITAL_STATUS_OPTIONS: Option[] = createOptions('MaritalStatus-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const IS_SPOUSE_MEMBER_OPTIONS: Option[] = createOptions('SpouseMember-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const HAS_CHILDREN_OPTIONS: Option[] = createOptions('HasChildren-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const DEGREE_OPTIONS: Option[] = [
    { id: 'No', label: 'No' },
    { id: 'Yes', label: 'Yes' },
];

const adv_DegOptions = [
    "Attending Or Completed Ph.D",
    "Attending or Completed Graduate/Doctorate/Advanced Degree",
    "Master's Certificate",
    "Completed Bachelor's Degree",
    "Currently Attending a 4 year college",
    "Completed Associate's Degree, currently attending 4 year college",
    "Attended some years at 4 year college but dropped out",
    "Completed Associate's Degree",
    "Completed High School"
];

export const HIGHEST_EDU_OPTIONS = adv_DegOptions.map((option, index) => ({
    value: `degreeOption-${index + 1}`,
    inputDisplay: option
}));

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

export const GRAD_DATE_MONTH = months.map(month => ({
    value: month,
    inputDisplay: month
}));

const currentYear = 2023;
const years = Array.from({ length: 12 }, (_, i) => currentYear - i);

export const GRAD_DATE_YEAR = years.map(year => ({
    value: year.toString(),
    inputDisplay: year.toString()
}));

const majors = [
    "Accounting", "Anthropology", "Architecture", "Biology", "Business Administration",
    "Chemistry", "Civil Engineering", "Computer Science", "Criminal Justice", "Economics",
    "Education", "Electrical Engineering", "English Literature", "Environmental Science", "Finance",
    "History", "Journalism", "Marketing", "Mathematics", "Mechanical Engineering",
    "Medicine", "Music", "Nursing", "Philosophy", "Physics",
    "Political Science", "Psychology", "Sociology", "Software Engineering", "Theatre"
];
export const UNDERGRAD_MAJORS = majors.map(major => ({
    value: major,
    inputDisplay: major
}));

const universities = [
    "Arizona State University", "Ohio State University", "University of Florida", "University of Minnesota",
    "University of Texas at Austin", "Pennsylvania State University", "University of Michigan",
    "University of California, Los Angeles", "Florida International University", "Texas A&M University",
    "Indiana University", "Michigan State University"
];
export const TOP_UNDERGRAD_UNIVERSITIES = universities.map(university => ({
    value: university,
    inputDisplay: university
}));

const internships = [
    "Google - Software Engineering", "J.P. Morgan - Analyst", "NASA - Research",
    "Disney - Animation", "Tesla - Engineering", "Goldman Sachs - Summer Analyst",
    "Apple - Hardware", "UN - Intern", "Pfizer - Research",
    "NY Times - Editorial", "Netflix - Data Analysis", "Microsoft - Program Manager"
];

export const COMMON_INTERNSHIPS = internships.map(internship => ({
    value: internship,
    inputDisplay: internship
}));

const incomeTypes = [
    "401(k) dist.",
    "Bonus",
    "Commission",
    "Dividend",
    "Hourly wage",
    "Interest",
    "IRA dist.",
    "Other income",
    "Rental income",
    "Salary",
    "1099 / Self - Employed / Side Hustle",
    "Stipend"
];

export const INCOME_TYPE_OPTIONS = incomeTypes.map(incomeType => ({
    value: incomeType,
    inputDisplay: incomeType
}));
;

export const debtAccountTypes = {
    label: 'Student Loan Account',
    options: [
        {
            label: 'Federal Student Loan',
        },
        {
            label: 'Parent PLUS loan',
        },
        {
            label: 'Perkins loan',
        },
        {
            label: 'Private student loan',
        },
    ],
};

export const debtAccountTypes2 = {
    label: 'Other Account',
    options: [
        {
            label: 'Credit Card',
        },
        {
            label: 'Home mortgage',
        },
        {
            label: 'Rental property mortgage',
        },
        {
            label: 'Auto loan',
        },
        {
            label: 'Personal loan',
        },
        {
            label: 'Other debt',
        },
    ],
};

export const expenseOptions1 = {
    label: 'Child Dependent Costs',
    options: [
        {
            label: 'Child Dependent Costs',
        },
    ],
};
export const expenseOptions2 = {
    label: 'Education',
    options: [
        {
            label: 'Books/Magazines/Newspapers',
        },
        {
            label: 'Continuing Education',
        },
    ],
};
export const expenseOptions3 = {
    label: 'Food and Drinks',
    options: [
        {
            label: 'Groceries',
        },
        {
            label: 'Restaurants and Bars',
        },
    ],
};
export const expenseOptions4 = {
    label: 'Health and Medical Services',
    options: [
        {
            label: 'Healthcare',
        },
    ],
};
export const expenseOptions5 = {
    label: 'Housing',
    options: [
        {
            label: 'HOA Fees',
        },
        {
            label: 'Home Improvement/Maintenance',
        },
        {
            label: 'Property Tax',
        },
        {
            label: 'Rent Boarding',
        },
    ],
};
export const expenseOptions6 = {
    label: 'Leisure',
    options: [
        {
            label: 'Entertainment',
        },
        {
            label: 'Hobbies/Sports',
        },
        {
            label: 'Online/Streaming/Media',
        },
        {
            label: 'Travel',
        },
    ],
};
export const expenseOptions7 = {
    label: 'Pets',
    options: [
        {
            label: 'Pet Related',
        },
    ],
};
export const expenseOptions8 = {
    label: 'Self Improvement',
    options: [
        {
            label: 'Gym Self Improvement',
        },
        {
            label: 'Personal Care',
        },
        {
            label: 'Shopping',
        },
        {
            label: 'Clothing/shoes',
        },
        {
            label: 'Electronics',
        },
        {
            label: 'General Merchandise',
        },
    ],
};
export const expenseOptions9 = {
    label: 'Transportation',
    options: [
        {
            label: 'Auto Lease',
        },
        {
            label: 'Auto Service and Parts',
        },
        {
            label: 'Gasoline and Fuel',
        },
        {
            label: 'Taxi / Tolls / Parking / Public Transit',
        },
    ],
};
export const expenseOptions10 = {
    label: 'Utilities',
    options: [
        {
            label: 'Cable Satellite Internet',
        },
        {
            label: 'Electric Gas',
        },
        {
            label: 'Phone',
        },
        {
            label: 'Water / Trash',
        },
    ],
};
export const expenseOptions11 = {
    label: 'Other',
    options: [
        {
            label: 'Advisory Services',
        },
        {
            label: 'Bank Fees',
        },
        {
            label: 'Charitable Giving',
        },
        {
            label: 'Organizational Dues',
        },
        {
            label: 'Other Expenses',
        },
        {
            label: 'Professional Services',
        },
        {
            label: 'Rental Property Maintenance',
        },
        {
            label: 'Rental Property Management',
        },
    ],
};

export const allDebtAccountTypes = [
    debtAccountTypes,
    debtAccountTypes2
];

export const allExpenseOptions = [
    expenseOptions1,
    expenseOptions2,
    expenseOptions3,
    expenseOptions4,
    expenseOptions5,
    expenseOptions6,
    expenseOptions7,
    expenseOptions8,
    expenseOptions9,
    expenseOptions10,
    expenseOptions11
];

export interface Option {
    id: string;
    label: string;
}

export const HC_SELECT_PROFESSIONS: { id: string; label: string }[] = [
    { id: 'Doctor', label: 'Doctor' },
    { id: 'Teacher', label: 'Teacher' },
    { id: 'Engineer', label: 'Engineer' },
    { id: 'Nurse', label: 'Nurse' },
    { id: 'Police Officer', label: 'Police Officer' },
    { id: 'Lawyer', label: 'Lawyer' },
    { id: 'Accountant', label: 'Accountant' },
    { id: 'Chef', label: 'Chef' },
    { id: 'Artist', label: 'Artist' },
    { id: 'Architect', label: 'Architect' },
    { id: 'Electrician', label: 'Electrician' },
    { id: 'Graphic Designer', label: 'Graphic Designer' },
    { id: 'Firefighter', label: 'Firefighter' },
    { id: 'Psychologist', label: 'Psychologist' },
    { id: 'Pharmacist', label: 'Pharmacist' },
    { id: 'Journalist', label: 'Journalist' },
    { id: 'Social Worker', label: 'Social Worker' },
    { id: 'Dentist', label: 'Dentist' },
    { id: 'Pilot', label: 'Pilot' },
    { id: 'Construction Worker', label: 'Construction Worker' },
];

export const HC_YES_NO_OPTIONS: Option[] = [
    { id: 'No', label: 'No' },
    { id: 'Yes', label: 'Yes' },
];

export const HC_QUESTIONS = [
    {
        name: 'requireLicense',
        label: 'Does your profession require a license or professional designation in order to work?',
        type: 'radio',
        options: [
            { id: 'requireLicense-No', label: 'No' },
            { id: 'requireLicense-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'passExam',
        label: 'Did you pass the exams on the first attempt?',
        type: 'radio',
        options: [
            { id: 'passExam-No', label: 'No' },
            { id: 'passExam-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'haveDesignation',
        label: 'Do you have a designation that you voluntarily obtained?',
        type: 'radio',
        options: [
            { id: 'haveDesignation-No', label: 'No' },
            { id: 'haveDesignation-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'passDesignationExam',
        label: 'If you had to pass exams to obtain the designation, did you pass on the first attempt?',
        type: 'radio',
        options: [
            { id: 'passDesignationExam-No', label: 'No' },
            { id: 'passDesignationExam-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'memberOrganization',
        label: 'Are you a member of any professional networking associations?',
        type: 'radio',
        options: [
            { id: 'memberOrganization-No', label: 'No' },
            { id: 'memberOrganization-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'collegeAthlete',
        label: 'Did you play a club or NCAA sport in college?',
        type: 'radio',
        options: [
            { id: 'collegeAthlete-No', label: 'No' },
            { id: 'collegeAthlete-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'runMarathon',
        label: 'Do you run marathons, triathlons, or any other endurance type of contests?',
        type: 'radio',
        options: [
            { id: 'runMarathon-No', label: 'No' },
            { id: 'runMarathon-Yes', label: 'Yes' },
        ],
    },
    {
        name: 'servedMilitary',
        label: 'Have you served in the military without discharge?',
        type: 'radio',
        options: [
            { id: 'servedMilitary-No', label: 'No' },
            { id: 'servedMilitary-Yes', label: 'Yes' },
        ],
    },
];

export const EDU_OPTIONS_AP: Option[] = [
    { id: 'AP-No', label: 'No' },
    { id: 'AP-Yes', label: 'Yes' }
];
export const EDU_OPTIONS_GRAD: Option[] = [
    { id: 'GRAD-No', label: 'No' },
    { id: 'GRAD-Yes', label: 'Yes' }
];
export const EDU_OPTIONS_SCHOLARSHIP: Option[] = [
    { id: 'SCHOLARSHIP-No', label: 'No' },
    { id: 'SCHOLARSHIP-Yes', label: 'Yes' }
];
export const EDU_OPTIONS_TUITION: Option[] = [
    { id: 'TUITION-No', label: 'No' },
    { id: 'TUITION-Yes', label: 'Yes' }
];

export const SPOUSE_EDU_OPTIONS_AP: Option[] = createOptions('SpouseAP-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_EDU_OPTIONS_GRAD: Option[] = createOptions('SpouseGRAD-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_EDU_OPTIONS_SCHOLARSHIP: Option[] = createOptions('SpouseSCHOL-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_EDU_OPTIONS_TUITION: Option[] = createOptions('SpouseTUITION-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const OTHER_LANGUAGES: Option[] = createOptions('otherLanguage-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const MULTIPLE_LANGUAGES: Option[] = createOptions('multipleLanguages-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPEAKING_LEVEL: Option[] = createOptions('speakingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const READING_LEVEL: Option[] = createOptions('readingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const WRITING_LEVEL: Option[] = createOptions('writingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);

export const OTHER_LANGUAGES2: Option[] = createOptions('otherLanguage2-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const MULTIPLE_LANGUAGES2: Option[] = createOptions('multipleLanguages2-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPEAKING_LEVEL2: Option[] = createOptions('speakingLevel2-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const READING_LEVEL2: Option[] = createOptions('readingLevel2-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const WRITING_LEVEL2: Option[] = createOptions('writingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_OTHER_LANGUAGES: Option[] = createOptions('spouseOtherLanguage-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const SPOUSE_MULTIPLE_LANGUAGES: Option[] = createOptions('spouseMultipleLanguages-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_SPEAKING_LEVEL: Option[] = createOptions('spouseSpeakingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_READING_LEVEL: Option[] = createOptions('spouseReadingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_WRITING_LEVEL: Option[] = createOptions('spouseWritingLevel-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_OTHER_LANGUAGES2: Option[] = createOptions('spouseOtherLanguage2-', [
    { label: 'No' },
    { label: 'Yes' },
]);

export const SPOUSE_MULTIPLE_LANGUAGES2: Option[] = createOptions('spouseMultipleLanguages2-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_SPEAKING_LEVEL2: Option[] = createOptions('spouseSpeakingLevel2-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_READING_LEVEL2: Option[] = createOptions('spouseReadingLevel2-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const SPOUSE_WRITING_LEVEL2: Option[] = createOptions('spouseWritingLevel2-', [
    { label: 'Beginner' },
    { label: 'Proficient' },
    { label: 'Fluent' },
]);
export const FINANCIAL_SOFTWARE: Option[] = createOptions('useFinancialSoftware-', [
    { label: 'No' },
    { label: 'Yes' }
]);
export const FINANCIAL_LOAN: Option[] = createOptions('delinquentLoan-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const SPOUSE_FINANCIAL_SOFTWARE: Option[] = createOptions('spouseUseFinancialSoftware-', [
    { label: 'No' },
    { label: 'Yes' }
]);
export const SPOUSE_FINANCIAL_LOAN: Option[] = createOptions('spouseDelinquentLoan-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const RESPONSIBLE_FOR_LOAN: Option[] = createOptions('isResponsible-', [
    { label: 'No' },
    { label: 'Yes' },
]);
export const TYPE_LOAN_INFO: Option[] = [
    { id: 'Estimated balance', label: 'Estimated balance' },
    { id: 'Complete loan details', label: 'Complete loan details' }
];
const plan_Options = [
    "Standard",
    "Extended",
    "Income-based repayment (IBR)",
    "IBR for new borrowers",
    "Pay as you earn (PAYE)",
    "Save on a valuable education (SAVE)",
    "I'm not sure"
];

export const LOAN_PLAN_OPTIONS = plan_Options.map(option => ({
    value: option,
    inputDisplay: option
}));

export const expenseCategories = [
    { key: 'groceries', label: 'Groceries' },
    { key: 'hoaDues', label: 'HOA dues' },
    { key: 'propertyTaxes', label: 'Property taxes' },
    { key: 'rent', label: 'Rent' },
    { key: 'clothing', label: 'Clothing' },
    { key: 'fuel', label: 'Fuel' },
    { key: 'power', label: 'Power' },
    { key: 'phone', label: 'Phone' },
    { key: 'water', label: 'Water' },
];