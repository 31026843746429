import { useState } from 'react';
import { EuiHeader, EuiText, EuiHeaderSectionItem, EuiTourStep, EuiButton, EuiIcon }
  from '@elastic/eui';
import Logo from '../components/Global/FitbuxLogo';
import Flyout from '../components/Global/Flyout';
import FitbuxScore from '../components/Global/FitbuxScore';
import ProgressBar from '../components/Global/ProgressBar';
import MainContent from './MainContent';
import styled from '@emotion/styled'
import DebtFlyout from '../components/FinancialInfo/DebtFlyout';

const StyledHeader = styled(EuiHeader)`
  box-shadow: none;
  border-bottom: none;
  padding: 0 1rem; 
  block-size: 60px;
`

const StyledEuiHeaderSectionItem = styled(EuiHeaderSectionItem)`
  gap: 8px; 
`

const StyledEuiText = styled(EuiText)`
  font-size: 14px;
  line-height: 1.3;
  font-weight: 600;
`;

const TourStepCard = styled(EuiTourStep)`
  background-color: #ccE4F5; 
  p {
    font-size: 14px;
  }
  .tour-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
`;

const TourButton = styled(EuiButton)`
  background: transparent;
  border: 1px solid #343741;
  min-inline-size: 65px;
  height: 32px;
  font-size: 14px;
`;

function ProfileBuilderLayout() {
  const [isOpen, setIsOpen] = useState(true);
  const totalSteps = 17;
  const [currentStep, setCurrentStep] = useState(0);

  // Progress Bar Tracking
  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
    setIsOpen(false);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  // REMOVE DEMO PURPOSES ONLY
  const getRandomScore = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const randomFitbuxScore = getRandomScore(0, 1000);

  return (
    <>
      <Flyout />
      <StyledHeader position="fixed">
        <EuiHeaderSectionItem>
          <Logo link='/' className='logo-container' alt='fitbux logo' />
        </EuiHeaderSectionItem>
        <EuiHeaderSectionItem>
          <StyledEuiHeaderSectionItem aria-label="FitBux Score">
            <StyledEuiText>FitBUX<br />Score</StyledEuiText>
            <TourStepCard
              content={
                <EuiText>
                  <p>The FitBUX Score incorporates your budget, financial assets, debt, human capital, and behavior to represent your complete financial picture.</p>
                </EuiText>
              }
              isOpen={isOpen}
              maxWidth={360}
              onFinish={() => setIsOpen(false)}
              offset={8}
              step={1}
              stepsTotal={1}
              title={
                <div className='tour-header'>
                  <h3>FitBUX Score</h3>
                  <EuiIcon type="cross" onClick={() => setIsOpen(false)} />
                </div>
              }
              anchorPosition="rightUp"
              footerAction={
                <TourButton color="text" onClick={() => setIsOpen(false)}>
                  Done
                </TourButton>
              }
            >
              <FitbuxScore FitbuxScore={randomFitbuxScore}></FitbuxScore>
            </TourStepCard>

          </StyledEuiHeaderSectionItem>
        </EuiHeaderSectionItem>
      </StyledHeader>
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      <MainContent nextStep={handleNext} prevStep={handleBack} currentStep={currentStep} />
    </>
  );
}

export default ProfileBuilderLayout;