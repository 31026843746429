import { Formik, Form } from 'formik';
import FormError from '../Global/FormError';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow, EuiButton, EuiComboBox } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, LabelStyle, StyledFormRow, LabelAsterisk, StyledEuiButtonEmpty } from '../Global/StyledComponents';
import { HC_SELECT_PROFESSIONS, HC_QUESTIONS } from '../../constants/formOptions';
import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import HumanCapitalQuestions from './HumanCapitalQuestions';

interface HumanCapitalFormValues {
    humanCapital: {
        profession: string,
        requireLicense: string,
        passExam: string,
        haveDesignation: string,
        passDesignationExam: string,
        memberOrganization: string,
        collegeAthlete: string,
        runMarathon: string,
        servedMilitary: string,
    }[];
}

interface HumanCapitalProps {
    initialValues: HumanCapitalFormValues;
    onNext: (values: HumanCapitalFormValues) => void;
    prevStep: () => void;
}

function HumanCapital({ initialValues, onNext, prevStep }: HumanCapitalProps) {

    const handleSubmit = (formValues: HumanCapitalFormValues) => {
        onNext(formValues);
    }

    const formikConfig = useValidationForm(FormType.HumanCapital, handleSubmit);

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    <h1>Your Human Capital</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    <p>Human Capital looks at your behavior and how it affects your projected wealth and risk. Tell us a few things about your background so we can recommend the best course of action. You can answer the other questions later on your dashboard.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    validationSchema={formikConfig.validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    label={<LabelStyle>What is your profession or what will be your profession post graduation?<LabelAsterisk /></LabelStyle>}
                                >
                                    <EuiComboBox
                                        rowHeight={40}
                                        isClearable={false}
                                        options={HC_SELECT_PROFESSIONS}
                                        selectedOptions={[{ label: values.humanCapital[0].profession }]}
                                        onChange={(selected) => setFieldValue('humanCapital[0].profession', selected[0].label)}
                                        singleSelection={{ asPlainText: true }}
                                    />

                                </EuiFormRow>

                                <FormError names={[`humanCapital[0].profession`]} />

                                <StyledSpacer size='32px' />

                                {HC_QUESTIONS.map((question, index) => (
                                    <HumanCapitalQuestions
                                        fieldName="humanCapital"
                                        key={index}
                                        index={index}
                                        question={question}
                                        values={values}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        submitCount={submitCount}
                                    />
                                ))}

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    // type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    disabled={!isValid || isSubmitting}
                                >
                                    Finish
                                </EuiButton>

                                <StyledEuiButtonEmpty
                                    type="submit"
                                    color="primary"
                                    disabled={!isValid || isSubmitting}
                                >
                                    Continue Human Capital section
                                </StyledEuiButtonEmpty>
                                {/* <StyledSpacer size='48px' />
                                <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default HumanCapital;