import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow, EuiButton, EuiRadioGroup } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton } from '../Global/StyledComponents';
import { EDU_OPTIONS_AP, EDU_OPTIONS_GRAD, EDU_OPTIONS_SCHOLARSHIP, EDU_OPTIONS_TUITION } from '../../constants/formOptions';

interface HCEducationFormValues {
    wasApScholar: string,
    graduatedFromIbProgram: string,
    offeredMeritOrAcademicScholarship: string,
    offeredFullTuitionScholarship: string,
}

interface HCEducationProps {
    initialValues: HCEducationFormValues;
    onNext: (values: HCEducationFormValues) => void;
    prevStep: () => void;
}

function HCEducation({ initialValues, onNext, prevStep }: HCEducationProps) {

    const handleSubmit = (formValues: HCEducationFormValues) => {
        onNext(formValues);
    }

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    <h1>Your Education</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    <p>Our data indicates that these factors reduce the risk to your income.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    label="Were you an AP Scholar?"
                                >
                                    <EuiRadioGroup
                                        name="wasApScholar"
                                        options={EDU_OPTIONS_AP}
                                        idSelected={values.wasApScholar}
                                        onChange={value => setFieldValue('wasApScholar', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    label="Did you graduate from an international baccalaureate program?"
                                >
                                    <EuiRadioGroup
                                        name="graduatedFromIbProgram"
                                        options={EDU_OPTIONS_GRAD}
                                        idSelected={values.graduatedFromIbProgram}
                                        onChange={value => setFieldValue('graduatedFromIbProgram', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    label="Were you offered a merit or academic scholarship for college?"
                                >
                                    <EuiRadioGroup
                                        name="offeredMeritOrAcademicScholarship"
                                        options={EDU_OPTIONS_SCHOLARSHIP}
                                        idSelected={values.offeredMeritOrAcademicScholarship}
                                        onChange={value => setFieldValue('offeredMeritOrAcademicScholarship', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    label="Were you offered a scholarship that covered your entire tuition cost?"
                                >
                                    <EuiRadioGroup
                                        name="offeredFullTuitionScholarship"
                                        options={EDU_OPTIONS_TUITION}
                                        idSelected={values.offeredFullTuitionScholarship}
                                        onChange={value => setFieldValue('offeredFullTuitionScholarship', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    className='btn-text'
                                >
                                    Next
                                </EuiButton>

                                <StyledEuiButton color='text'>
                                    Finish
                                </StyledEuiButton>

                                <StyledSpacer size='48px' />

                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default HCEducation;