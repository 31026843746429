import { EuiIcon, EuiBasicTable, EuiBasicTableColumn, EuiLink } from '@elastic/eui';
import { StyledSpacer } from '../Global/StyledComponents';
import { Global, css } from '@emotion/react';

const globalStyles = css`
    .truncateText {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .table-border {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc; 
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        tr:last-child td {
            border-bottom: none;
        }
    }

    .table-container {
        max-height: 500px;
        overflow-y: auto;
    }
    
    .newRow {
        background-color: #E6F9F7;
        animation: fadeout 5s;
    }

    @keyframes fadeout {
        from { background-color: #E6F9F7; }
        to { background-color: transparent; }
    }
  
`;

interface AccountsTableProps {
    items: Item[];
}

export type Item = {
    type: string;
    owner: string;
    institution: string;
    account: string;
    balance: string;
    isBalancePending?: boolean;
    isNew?: boolean
};

function AccountsTable({ items }: AccountsTableProps) {

    const formatCurrency = (value: string) => {
        if (typeof value !== 'string' || value.trim() === '') {
            console.warn('formatCurrency was called with an invalid or empty value:', value);
            return '-';
        }

        const numericValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));

        if (isNaN(numericValue)) {
            console.error(`formatCurrency was called with a value that cannot be parsed as a number: ${value}`);
            return value;
        }

        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue);
    };


    const columns: Array<EuiBasicTableColumn<Item>> = [
        {
            field: 'type',
            name: 'Type',
            truncateText: true,
        },
        {
            field: 'owner',
            name: 'Owner',
            truncateText: true,
        },
        {
            field: 'institution',
            name: 'Institution',
            truncateText: true,
        },
        {
            field: 'account',
            name: 'Account',
            truncateText: true,

        },
        {
            field: 'balance',
            name: 'Balance',
            render: (balance: string, item?: Item) => {
                if (item && item.isBalancePending) {
                    return (
                        <div>
                            <EuiIcon type="link" /> <em>Pending</em>
                        </div>
                    );
                }
                return formatCurrency(balance);
            },
        },
        {
            name: '',
            align: 'center',
            render: (item: Item) => (
                <EuiLink onClick={() => alert('Edit action clicked for ' + item.owner)}>
                    Edit
                </EuiLink>
            ),
        },
    ];

    const getRowProps = (item: Item) => {
        return {
            className: item.isNew ? 'newRow' : ''
        };
    };

    if (items.length === 0) return null; if (!items || items.length === 0) return null;

    return (
        <>
            <Global styles={globalStyles} />
            <StyledSpacer size="32px" />
            <EuiBasicTable<Item>
                className="table-border table-container"
                columns={columns}
                items={items}
                rowProps={getRowProps}
                responsive={true}
                tableLayout='fixed'
            />
        </>
    );
}

export default AccountsTable;