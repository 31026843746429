import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiFormRow, EuiButton, EuiRadioGroup } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton } from '../Global/StyledComponents';
import { SPOUSE_FINANCIAL_LOAN, SPOUSE_FINANCIAL_SOFTWARE } from '../../constants/formOptions';

interface SpouseHCProactivenessFormValues {
    spouseUseFinancialSoftware: string,
    spouseDelinquentLoan: string,
}

interface SpouseHCProactivenessProps {
    initialValues: SpouseHCProactivenessFormValues;
    onNext: (values: SpouseHCProactivenessFormValues) => void;
    prevStep: () => void;
}

function SpouseHCProactiveness({ initialValues, onNext, prevStep }: SpouseHCProactivenessProps) {

    const handleSubmit = (formValues: SpouseHCProactivenessFormValues) => {
        onNext(formValues);
    }

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    {/* <h1>{initialValues.spouseFirstName}'s financial proactiveness</h1> */}
                    <h1>Tonya's financial proactiveness</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    // label={Does {initialValues.spouseFirstName} use personal financial management software to manage their finances?}
                                    label="Does Tonya use personal financial management software to manage their finances?"
                                >
                                    <EuiRadioGroup
                                        name="spouseUseFinancialSoftware"
                                        options={SPOUSE_FINANCIAL_SOFTWARE}
                                        idSelected={values.spouseUseFinancialSoftware}
                                        onChange={value => setFieldValue('spouseUseFinancialSoftware', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    // label={Has {initialValues.spouseFirstName} ever been delinquent on a loan?}
                                    label="Has Tonya ever been delinquent on a loan?"
                                >
                                    <EuiRadioGroup
                                        name="spouseDelinquentLoan"
                                        options={SPOUSE_FINANCIAL_LOAN}
                                        idSelected={values.spouseDelinquentLoan}
                                        onChange={value => setFieldValue('spouseDelinquentLoan', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    className='btn-text'
                                >
                                    Finish
                                </EuiButton>

                                {/* <StyledEuiButton color='text'>
                                    Finish
                                </StyledEuiButton> */}

                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default SpouseHCProactiveness;