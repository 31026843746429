import * as Yup from 'yup';

export enum FormType {
    PersonalInfo,
    EducationInfo,
    spouseEducation,
    FinancialInfo,
    spouseIncome,
    HumanCapital,
    spouseHumanCapital
}

export const useValidationForm = (formType: FormType, onSubmit: (values: any) => void) => {
    let validationSchema;
    let initialValues;

    switch (formType) {
        case FormType.PersonalInfo:
            initialValues = {
                dateOfBirth: undefined,
                zipCode: '',
                gender: '',
                isMarried: '',
                isSpouseMember: '',
                spouseFirstName: '',
                spouseLastName: '',
                spouseGender: '',
                spouseDateOfBirth: undefined,
                hasChildren: '',
                children: [{ name: '', dob: undefined }],
            };
            validationSchema = getPersonalInfoValidationSchema();
            break;

        case FormType.EducationInfo:
            initialValues = {
                degree: '',
                education: [
                    { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
                ]
            };
            validationSchema = getEducationInfoValidationSchema();
            break;

        case FormType.spouseEducation:
            initialValues = {
                spouseDegree: '',
                spouseEducation: [
                    { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
                ]
            };
            validationSchema = getspouseEducationValidationSchema();
            break;

        case FormType.FinancialInfo:
            initialValues = {
                monthlySalary: 0,
                annualSalary: 0,
            };
            validationSchema = getFinancialInfoValidationSchema();
            break;

        case FormType.spouseIncome:
            initialValues = {
                spouseMonthlySalary: 0,
                spouseAnnualSalary: 0,
            };
            validationSchema = getspouseIncomeValidationSchema();
            break;

        case FormType.HumanCapital:
            initialValues = {
                humanCapital: [
                    {
                        profession: '',
                        requireLicense: '',
                        passExam: '',
                        haveDesignation: '',
                        passDesignationExam: '',
                        memberOrganization: '',
                        collegeAthlete: '',
                        runMarathon: '',
                        servedMilitary: '',
                    }
                ]
            };
            validationSchema = getHumanCapitalValidationSchema();
            break;
        
        case FormType.spouseHumanCapital:
            initialValues = {
                spouseHumanCapital: [
                    {
                        profession: '',
                        requireLicense: '',
                        passExam: '',
                        haveDesignation: '',
                        passDesignationExam: '',
                        memberOrganization: '',
                        collegeAthlete: '',
                        runMarathon: '',
                        servedMilitary: '',
                    }
                ]
            };
            validationSchema = getspouseHumanCapitalValidationSchema();
            break;

        default:
            throw new Error("Unsupported form type");
    }

    return {
        initialValues,
        validationSchema,
        onSubmit
    };
};

const getPersonalInfoValidationSchema = () => {
    return Yup.object().shape({
        dateOfBirth: Yup.date().required('This field is required.'),
        zipCode: Yup.string().required('This field is required.'),
        gender: Yup.string().required('This field is required.'),
        isMarried: Yup.string().required('This field is required.'),
        hasChildren: Yup.string().required('This field is required.'),
        children: Yup.array().when('hasChildren', (hasChildrenValue: any, schema: Yup.ArraySchema<any, any, any, any>) => {

            const value = Array.isArray(hasChildrenValue) ? hasChildrenValue[0] : hasChildrenValue;

            if (value === 'HasChildren-Yes') {
                return schema.of(
                    Yup.object().shape({
                        name: Yup.string().required('This field is required.'),
                        dob: Yup.date().required('This field is required.')
                    })
                ).required('At least one child entry is required.');
            }

            return schema.of(
                Yup.object().shape({
                    name: Yup.string(),
                    dob: Yup.date()
                })
            );
        }),
        spouseFirstName:
            Yup.string().when('isMarried', (isMarried: any, schema: Yup.StringSchema) => {
                let maritalStatus = isMarried[0];
                if (maritalStatus === "MaritalStatus-Yes") {
                    return schema.required('This field is required.');
                } else if (maritalStatus === 'MaritalStatus-No') {
                    return schema;
                } else {
                    return schema;
                }
            }),
        spouseDateOfBirth:
            Yup.date().when('isMarried', (isMarried: any, schema: Yup.DateSchema) => {
                let maritalStatus = Array.isArray(isMarried) ? isMarried[0] : isMarried;
                if (maritalStatus === 'MaritalStatus-Yes') {
                    return schema.required('This field is required.');
                } else if (maritalStatus === 'MaritalStatus-No') {
                    return schema;
                } else {
                    return schema;
                }
            }),
        spouseGender:
            Yup.string().when('isMarried', (isMarried: any, schema: Yup.StringSchema) => {
                let maritalStatus = isMarried[0];
                if (maritalStatus === "MaritalStatus-Yes") {
                    return schema.required('This field is required.');
                } else if (maritalStatus === 'MaritalStatus-No') {
                    return schema;
                } else {
                    return schema;
                }
            }),
    });
}

const getEducationInfoValidationSchema = () => {
    return Yup.object({
        degree: Yup.string().required('This field is required.'),
        education: Yup.array().when('degree', (degree: any, schema: Yup.ArraySchema<any, any, any, any>) => {
            const actualDegreeValue = Array.isArray(degree) ? degree[0] : degree;
            if (actualDegreeValue === 'Yes') {
                return schema.of(
                    Yup.object().shape({
                        university: Yup.string().required('This field is required.'),
                        major: Yup.string().required('This field is required.'),
                        gradMonth: Yup.string().required('This field is required.'),
                        gradYear: Yup.string().required('This field is required.'),
                        adv_deg: Yup.string().required('This field is required.')
                    })
                );
            }
            return schema.of(Yup.object().shape({}));
        })
    });
}

const getspouseEducationValidationSchema = () => {
    return Yup.object({
        spouseDegree: Yup.string().required('This field is required.'),
        spouseEducation: Yup.array().when('spouseDegree', (spouseDegree: any, schema: Yup.ArraySchema<any, any, any, any>) => {
            const actualDegreeValue = Array.isArray(spouseDegree) ? spouseDegree[0] : spouseDegree;
            if (actualDegreeValue === 'Yes') {
                return schema.of(
                    Yup.object().shape({
                        university: Yup.string().required('This field is required.'),
                        major: Yup.string().required('This field is required.'),
                        gradMonth: Yup.string().required('This field is required.'),
                        gradYear: Yup.string().required('This field is required.'),
                        adv_deg: Yup.string().required('This field is required.')
                    })
                );
            }
            return schema.of(Yup.object().shape({}));
        })
    });
}

const getFinancialInfoValidationSchema = () => {
    return Yup.object({
        monthlySalary: Yup.string().required("This field is required. Enter '0' if not applicable."),
        annualSalary: Yup.string().required("This field is required. Enter '0' if not applicable."),
    });
}

const getspouseIncomeValidationSchema = () => {
    return Yup.object({
        spouseMonthlySalary: Yup.string().required("This field is required. Enter '0' if not applicable."),
        spouseAnnualSalary: Yup.string().required("This field is required. Enter '0' if not applicable."),
    });
}

const getHumanCapitalValidationSchema = () => {
    return Yup.object().shape({
        humanCapital: Yup.array().of(
            Yup.object().shape({
                profession: Yup.string().required('This field is required.'),
                requireLicense: Yup.string().required('This field is required.'),
                passExam: Yup.string().when("requireLicense", (requireLicense: any, schema) => {
                    return requireLicense === 'Yes'
                        ? schema.required("This field is required.")
                        : schema.notRequired();
                }),
                haveDesignation: Yup.string().required('This field is required.'),
                passDesignationExam: Yup.string().when("haveDesignation", (haveDesignation: any, schema) => {
                    return haveDesignation === 'Yes'
                        ? schema.required("This field is required.")
                        : schema.notRequired();
                }),
                memberOrganization: Yup.string().required('This field is required.'),
                collegeAthlete: Yup.string().required('This field is required.'),
                runMarathon: Yup.string().required('This field is required.'),
                servedMilitary: Yup.string().required('This field is required.'),
            })
        )
    });
};

const getspouseHumanCapitalValidationSchema = () => {
    return Yup.object().shape({
        humanCapital: Yup.array().of(
            Yup.object().shape({
                profession: Yup.string().required('This field is required.'),
                requireLicense: Yup.string().required('This field is required.'),
                passExam: Yup.string().when("requireLicense", (requireLicense: any, schema) => {
                    return requireLicense === 'Yes'
                        ? schema.required("This field is required.")
                        : schema.notRequired();
                }),
                haveDesignation: Yup.string().required('This field is required.'),
                passDesignationExam: Yup.string().when("haveDesignation", (haveDesignation: any, schema) => {
                    return haveDesignation === 'Yes'
                        ? schema.required("This field is required.")
                        : schema.notRequired();
                }),
                memberOrganization: Yup.string().required('This field is required.'),
                collegeAthlete: Yup.string().required('This field is required.'),
                runMarathon: Yup.string().required('This field is required.'),
                servedMilitary: Yup.string().required('This field is required.'),
            })
        )
    });
};


