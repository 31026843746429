import React from "react";

interface FlyoutInfoProps {
    infoType: 'human-capital' | 'fitbux-score' | 'children' | 'add-manual-account' | string;
    componentType: 'header' | 'body' | 'contentAction' | 'submitAction' | 'cancelAction';
}

function FlyoutInfo({ infoType, componentType }: FlyoutInfoProps) {
    let header = '';
    let body: React.ReactNode = '';
    let contentAction: string | undefined;
    let submitAction: string | undefined;
    let cancelAction: string | undefined;

    switch (infoType) {
        case 'human-capital':
            header = 'Human Capital';
            body = (
                <>
                    <p>Human capital is a combination of our future income and the risk to that income. Its important because it dictates everything we do financially, i.e. how we pay down student loans, should you rent or buy a house, how much insurance you need, investment allocations, etc.</p>

                    <p>Think about a technology business. Their largest assets are intangibles, i.e. their code, their technology, copyrights, patents, etc… In the long run, businesses realize the value of these things in the form of revenue.</p>

                    <p>Human capital is your intangible asset. However, instead of technology, copyrights, and patents, you have education, skills, and behavioral traits that have been developed over time. Also, instead of monetizing your human capital overtime via revenue you do so in the form of income. I.e. you recognize the value of your human capital over time in the form of income.</p>

                    <p>Analyzing human capital is one way you can determine trade-offs between choices. For example, <a href="https://www.fitbux.com/articles/physical-therapist-salary/">should you get an advanced degree such as your DPT or not</a>. You can also see what professions have the best financial outlook for yourself based on the expected increase in human capital value.</p>
                </>
            );
            contentAction = 'Listen to the Podcast';
            submitAction = 'Done';
            break;
        case 'fitbux-score':
            header = 'FitBUX Score';
            body = (
                <>
                    <p>The FitBUX Score is a revolutionary tool in the realm of financial planning, designed to make the complex process of managing and comparing financial options straightforward and user-friendly. In the traditional financial landscape, understanding and comparing different financial plans can be a daunting task, often mired in pages of dense data and confusing metrics. The FitBUX Score simplifies this by distilling your financial information, including assets, debts, human capital, and behavioral patterns, into one comprehensive, easy-to-understand number. This innovative approach allows you to effortlessly compare different financial scenarios and make informed decisions without getting lost in the details.</p>

                    <p>With the FitBUX Score, financial planning becomes a breeze. Whether you're evaluating loan repayment options, considering investment strategies, or planning for future financial goals, this tool provides a clear, concise benchmark for comparison. It's like having a financial GPS that guides you through the myriad of choices, highlighting the impact of each decision on your overall financial health. The FitBUX Score empowers you to navigate the financial landscape with confidence, providing a straightforward way to assess and compare various financial paths. Embrace a smarter, simpler way to plan your finances with the FitBUX Score, and take control of your financial future with ease and clarity.</p>
                </>
            );
            submitAction = 'Done';
            break;
        case 'children':
            header = 'Children';
            body = (
                <>
                    <p>Children add cost and uncertainty to your financial plan. Therefore, you have less cash flow and increased risk. Thus, children reduce your FitBUX Score.</p>

                    <p>You can potentially decrease the risk by having the appropriate types of insurances (life and disability) as well as manipulating your debt payments to increase your budget flexibility. Be sure to ask us about these topics when you schedule a call.</p>
                </>
            );
            submitAction = 'Done';
            break;
        default:
            header = 'Default Header';
            body = 'Default Information';
    }

    if (componentType === 'header') {
        return header;
    } else if (componentType === 'body') {
        return body;
    } else if (componentType === 'contentAction') {
        return contentAction || '';
    } else if (componentType === 'submitAction') {
        return submitAction || '';
    } else if (componentType === 'cancelAction') {
        return cancelAction || '';
    } else  {
        return null;
    }
}

export default FlyoutInfo;