import { useDispatch } from 'react-redux';
import { addDebtAccount } from '../../redux/actions/actions';
import { EuiModal, EuiModalHeader, EuiModalHeaderTitle, EuiModalBody, EuiModalFooter, EuiText, EuiIcon } from '@elastic/eui';
import { StyledEuiButtonEmpty } from '../Global/StyledComponents';
import styled from '@emotion/styled';
import ConnectAccount from './ConnectAccount';
import { Item } from './AccountsTable';
import { useFlyout } from '../Global/FlyoutContext';

export const StyledEuiModalHeader = styled(EuiModalHeader)`
    padding-inline: 24px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const StyledEuiIcon = styled(EuiIcon)`
    width: 24px;
    height: 24px;
`;

interface AddAccountProps {
    values: {
        debtAccounts: {
            accountType: string,
            owner: string,
            planOptions: string,
            financeInst: string,
            accountName: string,
            balance: string,
            payOffMonthly: boolean,
            interestRate: string,
            minPayment: string,
            isResponsible: string,
            loanInfoType: string,
            loanDetails: {
                currentBalance: string,
                interestRate: string,
            }[],
            estMarkeValue: string,
        }[],
    }
    isModalVisible: boolean;
    closeModal: () => void;
    onAccountConnected: (newAccount: Item) => void;
}

function AddAccount({ values, isModalVisible, closeModal, onAccountConnected }: AddAccountProps) {

    const dispatch = useDispatch();
    
    const { toggleDebtFlyout } = useFlyout();
    
    const openDebtFlyout = () => {
        dispatch(addDebtAccount());
        toggleDebtFlyout();
        closeModal();
    };

    if (!isModalVisible) return null;

    return (

        <EuiModal onClose={closeModal} style={{ maxWidth: 600 }}>
            <StyledEuiModalHeader>
                <EuiModalHeaderTitle>
                    Add debt account
                </EuiModalHeaderTitle>
                <StyledEuiIcon
                    type="cross"
                    onClick={closeModal}
                    style={{
                        cursor: 'pointer'
                    }}
                />
            </StyledEuiModalHeader>
            <EuiModalBody>
                <EuiText size='m'>
                    <p>We use Plaid to connect your financial accounts quickly and securely. Once your accounts are connected, they will update automatically so you have the most complete picture of your finances. You also have the option of adding your accounts manually instead.</p>
                </EuiText>
            </EuiModalBody>
            <EuiModalFooter>
                <StyledEuiButtonEmpty onClick={openDebtFlyout}>Add account manually</StyledEuiButtonEmpty>
                <ConnectAccount onAccountConnected={onAccountConnected} closeModal={closeModal} />
            </EuiModalFooter>
        </EuiModal>
    );
}

export default AddAccount;