import { FormikHelpers } from 'formik';

export const formatToCurrency = (value: string): string => {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) {
    return Math.round(numberValue).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  }
  return '';
};

export const isNumericInput = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};

export const handleInputChange = (name: string, setFieldValue: FormikHelpers<any>['setFieldValue']) => {
  return (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isNumericInput(value) || value === "") {
      setFieldValue(name, value);
    }
  };
};

export const handleCurrencyBlur = (
  name: string,
  setFieldValue: FormikHelpers<any>['setFieldValue']
) => (event: React.FocusEvent<HTMLInputElement>) => {
  let value = event.target.value;
  value = value.replace(/[^0-9.-]+/g, "");

  if (value === '') {
    setFieldValue(name, '');
  } else {
    const formattedValue = formatToCurrency(value);
    setFieldValue(name, formattedValue);
  }
};

export const handleRoundedCurrencyBlur = (
  name: string,
  setFieldValue: FormikHelpers<any>['setFieldValue']
) => (event: React.FocusEvent<HTMLInputElement>) => {
  let value = event.target.value;
  value = value.replace(/[^0-9.-]+/g, "");

  if (value === '') {
    setFieldValue(name, '');
  } else {
    const numberValue = parseFloat(value);
    const roundedValue = isNaN(numberValue) ? '' : Math.round(numberValue).toString();
    setFieldValue(name, roundedValue);
  }
  };

export const handlePercentageBlur = (
  name: string, 
  setFieldValue: FormikHelpers<any>['setFieldValue']
) => (event: React.FocusEvent<HTMLInputElement>) => {
  let value = event.target.value;
  value = value.replace(/[^0-9.]+/g, ""); // Remove non-numeric characters except for decimal point

  if (value === '') {
    setFieldValue(name, '');
  } else {
    const numberValue = parseFloat(value);
    // Format to 2 decimal places and add percentage symbol
    const formattedValue = isNaN(numberValue) ? '' : numberValue.toFixed(2) + '%';
    setFieldValue(name, formattedValue);
  }
  };

  export const handleAnnualSalaryUpdate = (
    monthlySalary: string,
    annualSalaryFieldName: string,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    const monthlySalaryValue = parseFloat(monthlySalary.replace(/[^0-9.-]+/g, ""));
    if (!isNaN(monthlySalaryValue)) {
      const annualSalary = monthlySalaryValue * 12;
      const annualSalaryFormatted = formatToCurrency(annualSalary.toString());
      setFieldValue(annualSalaryFieldName, annualSalaryFormatted);
    }
  };



