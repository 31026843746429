import { useState } from 'react';
import { Formik, Form } from 'formik';
import { EuiPopover, EuiButton, EuiContextMenuPanel, EuiContextMenuItem, } from '@elastic/eui';
import { StyledEuiButtonEmpty } from '../Global/StyledComponents';
import { Item } from './AccountsTable';
import { Global, css } from '@emotion/react';

const globalStyles = css`
    .euiPopover__panel { 
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
`;


interface ConnectAccountProps {
    onAccountConnected: (account: Item) => void;
    closeModal: () => void;
}

function ConnectAccount({ onAccountConnected, closeModal }: ConnectAccountProps) {

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const onButtonClick = () => setIsPopoverOpen(!isPopoverOpen);
    const closePopover = () => setIsPopoverOpen(false);

    const iconType = isPopoverOpen ? "arrowUp" : "arrowDown";

    const handleAccountClick = async (ownerName: string) => {
        try {
            const response = await fetch(`https://fb-debts-2.free.beeceptor.com/accounts`);
            let accounts = await response.json();

            if (accounts.length === 0) {
                console.error("No accounts found");
                return;
            }

            let randomIndex = Math.floor(Math.random() * accounts.length);
            let selectedAccount = { ...accounts[randomIndex], owner: ownerName, isNew: true };

            onAccountConnected(selectedAccount);
            closeModal();
        } catch (error) {
            console.error('Error connecting account:', error);
        }
    };

    const items = [
        <EuiContextMenuItem key="Sarah" onClick={() => handleAccountClick('Sarah')}>
            Sarah’s account
        </EuiContextMenuItem>,
        <EuiContextMenuItem key="Kevin" onClick={() => handleAccountClick('Kevin')}>
            Kevin's account
        </EuiContextMenuItem>,
    ];

    return (
        <>
            <Global styles={globalStyles} />
            <EuiPopover
                className='popover-styles'
                button={
                    <EuiButton fill iconType={iconType} iconSide="right" onClick={onButtonClick}>
                        Connect account
                    </EuiButton>
                }
                isOpen={isPopoverOpen}
                closePopover={closePopover}
                hasArrow={false}
            >
                <EuiContextMenuPanel items={items} />
            </EuiPopover>
        </>
    );
}

export default ConnectAccount;