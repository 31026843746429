import styled from '@emotion/styled';
import { EuiButtonIcon, EuiButton, EuiCallOut, EuiFormRow, EuiLink, EuiHorizontalRule, EuiTitle, EuiButtonEmpty, EuiIcon, EuiToast } from '@elastic/eui';
import { BsAsterisk } from 'react-icons/bs';
import { Row } from 'react-bootstrap';

export const StyledFormRow = styled(Row)`
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
    
    @media (max-width: 576px) {
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
    }

    @media (min-width: 576px) and (max-width: 992px) {
      padding-left: 48px;
    }
`;

export const StyledEuiToast = styled(EuiToast)`
    position: absolute;
    top: 6rem;
    right: 2rem;
    display: flex;
    max-width: 450px;
    border-left: 3px solid #00BFB3;
    border-block-start: none;
    .css-19d00ai-euiToastHeader-withBody {
      padding: 0;
    }
    .euiText {
      max-width: 325px;
    }
    svg {
      fill: #00BFB3;
    }
    button {
      svg {
        fill: #343741;
        width: 24px;
        height: 24px;
      }
    }
`;

export const StyledSpacer = styled.div<{ size?: string }>`
    block-size: ${props => props.size || '32px'};
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
`;

export const StyledEuiHorizontalRule = styled(EuiHorizontalRule)<{ maxWidth?: string }>`
    margin-top: 0;
    margin-bottom: 0;
    max-width: ${props => props.maxWidth || '100%'};
`;

export const StyledEuiIcon = styled(EuiIcon)`
    cursor: pointer;
    height: 20px;
    width: 20px;
`;

export const StyledEuiTitle = styled(EuiTitle)`
    font-weight: 600;
`;

export const StyledSpan = styled.span`
    display: flex;
    align-items: center;
    font-size: 16px; 
    font-weight: 500;
`;

export const LabelStyle = styled.span`
    display: block;
    font-size: 16px; 
    font-weight: 500;
`;

export const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const StyledEuiButtonIcon = styled(EuiButtonIcon)`
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const StyledEuiButton = styled(EuiButton)`
  border: 1px solid #343741;
  font-size: 16px;
`;

export const StyledEuiCallOut = styled(EuiCallOut)`
  .euiTitle {
    font-weight: 600;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  @media (min-width: 576px) and (max-width: 992px) {
    max-width: 600px;
  }
`;

export const StyledBsAsterisk = styled(BsAsterisk)`
    color: #1a1c21;
    font-size: 8px;
    margin-left: 2px;
    margin-top: -5px;
`;

export const LabelAsterisk = styled(BsAsterisk)`
    color: #1a1c21;
    font-size: 8px;
    margin-left: 2px;
    top: -5px;
    position: relative;
`;

export const StyledEuiFormRow = styled(EuiFormRow as any)`
  padding-bottom:20px;
`;

export const StyledEuiLink = styled(EuiLink)`
    text-decoration: underline;
`

export const StyledEuiButtonEmpty = styled(EuiButtonEmpty)`
  font-size: 16px;
  margin-left: 1rem;
`;

export const StyledLink = styled.a`
    text-decoration: underline;
`