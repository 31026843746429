import { Formik, Form } from 'formik';
import FormError from '../Global/FormError';
import moment from 'moment';
import {
    EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow,
    EuiDatePicker, EuiFieldText, EuiRadioGroup, EuiButton
} from '@elastic/eui';
import { Col } from 'react-bootstrap';
import {
    StyledBsAsterisk, StyledSpacer, StyledSpan, StyledFormRow, StyledEuiButtonIcon
} from '../Global/StyledComponents';
import { GENDER_OPTIONS, HAS_CHILDREN_OPTIONS } from '../../constants/formOptions';
import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import SpouseInfo from './SpouseInfo';
import ChildInfo from './ChildInfo';
import { useFlyout } from '../Global/FlyoutContext';

interface PersonalInfoFormValues {
    dateOfBirth?: Date;
    zipCode: string;
    gender: string;
    isMarried: string;
    isSpouseMember: string;
    spouseFirstName: string;
    spouseLastName: string;
    spouseGender: string;
    spouseDateOfBirth?: Date;
    hasChildren: string;
    children: { name: string; dob?: Date }[];
}

interface PersonalInfoProps {
    initialValues: PersonalInfoFormValues;
    onNext: (values: PersonalInfoFormValues) => void;
    prevStep: () => void;
}

function PersonalInfo({ initialValues, onNext, prevStep }: PersonalInfoProps) {

    const { toggleFlyout } = useFlyout();

    const handleSubmit = (formValues: PersonalInfoFormValues) => {
        onNext(formValues);
    }

    const formikConfig = useValidationForm(FormType.PersonalInfo, handleSubmit);

    return (
        <>
            <StyledFormRow>
                <Col lg={{ span: 5, offset: 2 }}>
                    <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                        Back
                    </EuiButtonEmpty>
                    <StyledSpacer size="32px" />
                    <EuiTitle size="l" className='header-font'>
                        <h1>Tell us about you</h1>
                    </EuiTitle>
                    <EuiSpacer size='l' />
                    <EuiText size='m'>
                        <p>Tell us a bit about you and we'll do the heavy lifting.</p>
                    </EuiText>
                    <StyledSpacer size='36px' />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={formikConfig.validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            setFieldValue,
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            submitCount,
                            validateField
                        }) => (
                            <>
                                <Form>
                                    <EuiFormRow
                                        label={<StyledSpan>Date of Birth<StyledBsAsterisk /></StyledSpan>}
                                        helpText="Example: 05/20/1990"
                                        isInvalid={submitCount > 0 && !!errors.dateOfBirth}
                                        error={errors.dateOfBirth ? [errors.dateOfBirth] : undefined}
                                        className='input-size'
                                    >
                                        <div>
                                            <EuiDatePicker
                                                showIcon={false}
                                                selected={values.dateOfBirth ? moment(values.dateOfBirth) : undefined}
                                                onChange={date => setFieldValue('dateOfBirth', date ? date.toDate() : undefined)}
                                                isInvalid={submitCount > 0 && !!errors.dateOfBirth}
                                            />
                                            {submitCount > 0 && errors.dateOfBirth && (
                                                <FormError names={["dateOfBirth"]} />
                                            )}
                                        </div>
                                    </EuiFormRow>

                                    <StyledSpacer size='32px' />

                                    <EuiFormRow
                                        label={<StyledSpan>Zip Code<StyledBsAsterisk /></StyledSpan>}
                                        isInvalid={submitCount > 0 && !!errors.zipCode}
                                        error={errors.zipCode ? [errors.zipCode] : undefined}
                                        className='input-size'
                                    >
                                        <EuiFieldText
                                            name="zipCode"
                                            isInvalid={submitCount > 0 && !!errors.zipCode}
                                            value={values.zipCode}
                                            onChange={e => setFieldValue('zipCode', e.target.value)}
                                        />
                                    </EuiFormRow>

                                    <FormError names={["zipCode"]} />

                                    <StyledSpacer size='32px' />

                                    <EuiFormRow
                                        label={<StyledSpan>Gender<StyledBsAsterisk /></StyledSpan>}
                                        isInvalid={submitCount > 0 && !!errors.gender}
                                        error={errors.gender ? [errors.gender] : undefined}
                                    >
                                        <EuiRadioGroup
                                            name="gender"
                                            options={GENDER_OPTIONS}
                                            idSelected={values.gender}
                                            onChange={value => setFieldValue('gender', value)}
                                        />
                                    </EuiFormRow>

                                    <FormError names={["gender"]} />

                                    <StyledSpacer size='32px' />

                                    <SpouseInfo
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        submitCount={submitCount}
                                        validateField={validateField}
                                    />

                                    <StyledSpacer size='32px' />

                                    <EuiFormRow
                                        label={
                                            <StyledSpan>Do you have any children?<StyledBsAsterisk />
                                                <StyledEuiButtonIcon
                                                    onClick={() => toggleFlyout('children')}
                                                    iconType="questionInCircle"
                                                    aria-label="Open Flyout"
                                                />
                                            </StyledSpan>
                                        }
                                        isInvalid={!!errors.hasChildren}
                                        error={errors.hasChildren ? [errors.hasChildren] : undefined}
                                    >
                                        <EuiRadioGroup
                                            name="hasChildren"
                                            idSelected={values.hasChildren}
                                            options={HAS_CHILDREN_OPTIONS}
                                            onChange={value => setFieldValue('hasChildren', value)}
                                        />
                                    </EuiFormRow>

                                    <FormError names={["hasChildren"]} />

                                    <ChildInfo
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        submitCount={submitCount}
                                        validateField={validateField}
                                    />

                                    <StyledSpacer size='48px' />

                                    <EuiButton
                                        type="submit"
                                        color='primary'
                                        fill
                                        isLoading={isSubmitting}
                                        disabled={(submitCount > 0 && !isValid) || isSubmitting}
                                    >
                                        Next
                                    </EuiButton>

                                    <StyledSpacer size='32px' />

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                </Form>
                            </>
                        )}
                    </Formik>
                </Col>
            </StyledFormRow>
        </>
    );
}

export default PersonalInfo;