import { useEffect } from 'react';

interface SetFieldValue {
    (field: string, value: any, shouldValidate?: boolean): void;
}

interface ValidateField {
    (name: string): void;
}

export const useFieldReset = (
    condition: boolean,
    fieldsToReset: string[],
    setFieldValue: SetFieldValue,
    validateField: ValidateField
) => {
    useEffect(() => {
        if (condition) {
            console.log('useFieldReset is running', {condition, fieldsToReset});
            fieldsToReset.forEach(field => {
                console.log('Resetting and validating field:', field);
                setTimeout(() => {
                    setFieldValue(field, '');
                    validateField(field);
                }, 1000);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);
}
