import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { EuiProvider } from '@elastic/eui';
import store from './redux/store/store';
import './styles/App.css';
import { FlyoutProvider } from './components/Global/FlyoutContext';
import ProfileBuilderLayout from './layouts/ProfileBuilderLayout';

function App() {
  return (
    <ReduxProvider store={store}>
      <EuiProvider>
        <FlyoutProvider>
          <ProfileBuilderLayout></ProfileBuilderLayout>
        </FlyoutProvider>
      </EuiProvider>
    </ReduxProvider>
  );
}

export default App;
