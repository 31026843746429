import { useState, createContext, useContext, useCallback } from 'react';

interface FlyoutContextProps {
    isFlyoutOpen: boolean;
    isDebtFlyoutOpen: boolean;
    infoType: string;
    toggleDebtFlyout: () => void;
    toggleFlyout: (type: string) => void;
    closeFlyout: () => void;
    closeDebtFlyout: () => void;
}

const FlyoutContext = createContext<FlyoutContextProps>({
    isFlyoutOpen: false,
    isDebtFlyoutOpen: false,
    infoType: '',
    toggleDebtFlyout: () => { },
    toggleFlyout: (type: string) => { },
    closeFlyout: () => { },
    closeDebtFlyout: () => { },
});

export const useFlyout = () => useContext(FlyoutContext);

interface FlyoutProviderProps {
    children: React.ReactNode;
}

export const FlyoutProvider: React.FC<FlyoutProviderProps> = ({ children }) => {
    const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);
    const [isDebtFlyoutOpen, setIsDebtFlyoutOpen] = useState(false);
    const [infoType, setInfoType] = useState('');

    const toggleFlyout = useCallback((type: string) => {
        setInfoType(type);
        setIsFlyoutOpen(!isFlyoutOpen);
    }, [isFlyoutOpen]);
    
    const toggleDebtFlyout = useCallback(() => {
        setIsDebtFlyoutOpen(!isDebtFlyoutOpen);
    }, [isDebtFlyoutOpen]);

    const closeFlyout = useCallback(() => {
        setIsFlyoutOpen(false);
    }, []);

    const closeDebtFlyout = useCallback(() => {
        setIsDebtFlyoutOpen(false);
    }, []);

    return (
        <FlyoutContext.Provider
            value={{ isFlyoutOpen, isDebtFlyoutOpen, infoType, toggleFlyout, toggleDebtFlyout, closeFlyout, closeDebtFlyout }}
        >
            {children}
        </FlyoutContext.Provider>
    );
};
