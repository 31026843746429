import { useState } from 'react';
import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiButton } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton, StyledEuiTitle, StyledEuiButtonEmpty, StyledEuiToast } from '../Global/StyledComponents';
import AddAccount from './AddAccount';
import AccountsTable, { Item } from './AccountsTable';
import DebtFlyout from './DebtFlyout';
import { ManualAccountFormValues } from './ManualAccountForm';

interface DebtsInfoFormValues {
    spouseFirstName: string;
    isMarried: string;
    education: {
        gradYear: string;
    }[];
    spouseEducation: {
        gradYear: string;
    }[];
    debtAccounts: {
        uniqueId: string | null;
        accountType: string;
        owner: string;
        planOptions: string;
        financeInst: string;
        accountName: string;
        balance: string;
        payOffMonthly: boolean;
        interestRate: string;
        minPayment: string;
        isResponsible: string;
        loanInfoType: string;
        estMarkeValue: string;
        loanDetails: {
            currentBalance: string;
            interestRate: string;
        }[];
    }[];
}

interface DebtsInfoProps {
    debtAccounts: DebtsInfoFormValues['debtAccounts'];
    initialValues: DebtsInfoFormValues;
    onNext: (values: DebtsInfoFormValues) => void;
    prevStep: () => void;
}

function DebtsInfo({ initialValues, onNext, prevStep }: DebtsInfoProps) {

    const handleSubmit = (formValues: DebtsInfoFormValues) => {
        onNext(formValues);
    }

    const [items, setItems] = useState<Item[]>([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => setIsModalVisible(true);
    const closeModal = () => setIsModalVisible(false);

    const [showToast, setShowToast] = useState(false);

    const onAccountConnected = (newAccount: Item) => {
        setItems(prevItems => [newAccount, ...prevItems]);
        setShowToast(true);
        
        setTimeout(() => {
            setItems(prevItems => prevItems.map(item => {
                if (item.account === newAccount.account) {
                    const updatedItem = { ...item };
                    delete updatedItem.isNew;
                    return updatedItem;
                }
                return item;
            }));
        }, 5000);
    
        setTimeout(() => setShowToast(false), 5000);
    };
    
    const onDebtAccountAdded = (debtAccountData: ManualAccountFormValues['debtAccounts'][0]) => {
        const newTableItem = {
            type: debtAccountData.accountType,
            owner: debtAccountData.owner,
            institution: debtAccountData.financeInst,
            account: debtAccountData.accountName,
            balance: debtAccountData.balance
        };
    
        setItems(prevItems => [...prevItems, newTableItem]);
    };
    
    console.log('DebtInfo', onDebtAccountAdded);

    const closeToast = () => {
        setShowToast(false);
    };

    return (
        <>
            {showToast && (
                <StyledEuiToast
                    color="success"
                    iconType="checkInCircleFilled"
                    onClose={closeToast}>
                    Account successfully connected! It could take a few minutes for the information to load.
                </StyledEuiToast>
            )}
            <DebtFlyout initialValues={initialValues} onDebtAccountAdded={onDebtAccountAdded} />
            <StyledFormRow>
                <Col lg={{ span: 6, offset: 2 }}>
                    <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                        Back
                    </EuiButtonEmpty>
                    <StyledSpacer size="32px" />
                    <EuiTitle size="l" className='header-font'>
                        <h1>Your Debts</h1>
                    </EuiTitle>
                    <EuiSpacer size='l' />
                    <EuiText size='m'>
                        <p>Include any student loans, credit card debt, or mortgages.</p>
                    </EuiText>
                    <StyledSpacer size='36px' />
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({
                            setFieldValue,
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            isSubmitting,
                            isValid,
                            submitCount,
                            validateField
                        }) => (
                            <>
                                <Form>

                                    <StyledEuiTitle size="xs">
                                        <h3>Add Debt</h3>
                                    </StyledEuiTitle>

                                    <StyledSpacer size="12px" />

                                    <EuiText size='m'>
                                        <p>Connect your accounts for updated financial info or add your accounts manually.</p>
                                    </EuiText>

                                    <StyledSpacer size="32px" />

                                    <StyledEuiButton
                                        color='text'
                                        iconType="plus"
                                        onClick={showModal}
                                    >
                                        Add debt account
                                    </StyledEuiButton>

                                    <AddAccount
                                        isModalVisible={isModalVisible}
                                        closeModal={closeModal}
                                        onAccountConnected={onAccountConnected}
                                        values={values}
                                    />

                                    <AccountsTable items={items} />

                                    <StyledSpacer size='48px' />

                                    <EuiButton
                                        type="submit"
                                        color='primary'
                                        fill
                                        isLoading={isSubmitting}
                                        className='btn-text'
                                    >
                                        Next
                                    </EuiButton>

                                    <StyledEuiButtonEmpty color="text">
                                        Save and Exit
                                    </StyledEuiButtonEmpty>

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

                                </Form>
                            </>
                        )}
                    </Formik>
                </Col>
            </StyledFormRow>
        </>
    );
}

export default DebtsInfo;