import { EuiTitle, EuiText, EuiButton, EuiImage, EuiSpacer, EuiShowFor } from '@elastic/eui';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import welcomeImg from '../assets/welcome_landing.svg';
import { StyledSpacer, StyledEuiLink } from '../components/Global/StyledComponents'
import { useFlyout } from '../components/Global/FlyoutContext';

const NumberList = styled.ol`
  list-style: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: baseline;
    
    b {
      font-weight: bold;
    }
    
    span {
      margin-left: 8px;
    }
  }
`;
const StyledEuiText = styled(EuiText)`
  font-size: 22px;
  line-height: 2.4rem;
  .landing-text {
    max-width: 600px;
    button {
      font-weight: 400;
    }
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 1.8rem;
  }
`;
const StyledMobileRow = styled(Row)`
  @media (max-width: 576px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
const StyledMobileEuiSpacer = styled(EuiSpacer)`
  @media (max-width: 576px) {
      block-size: 36px;
  }
`;
const StyledImageCol = styled(Col)`
  @media (max-width: 992px) {
    order: -1;
  }
  @media (min-width: 576px) and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

interface PersonalInfoLandingProps {
  onNextLanding: () => void;
}

function PersonalInfoLanding({ onNextLanding }: PersonalInfoLandingProps) {

  const { toggleFlyout } = useFlyout();

  return (
    <>
      <StyledMobileRow className="justify-content-sm-center align-items-sm-center">
        <Col sm={{ span: 8 }} lg={{ span: 7 }}>
          <EuiTitle size="l" className='header-font'>
            <h1>Welcome to FitBUX</h1>
          </EuiTitle>
          <EuiSpacer size='l' />
          <StyledEuiText size='m'>
            <div className='landing-text'>
              To get started, let's build your FitBUX profile. This will value your <StyledEuiLink onClick={() => toggleFlyout('human-capital')}>human capital</StyledEuiLink>, calculate your <StyledEuiLink onClick={() => toggleFlyout('fitbux-score')}>FitBUX score</StyledEuiLink>, and get you set up to create your financial plan.
            </div>
          </StyledEuiText>
          <StyledSpacer size="48px" />
          <NumberList>
            <StyledEuiText size='m'>
              <li><b>1.</b><span><b>Personal Info</b></span></li>
              <EuiSpacer size='s' />
              <li>2. <span>Financial Info</span></li>
              <EuiSpacer size='s' />
              <li>3. <span>Human Capital</span></li>
            </StyledEuiText>
          </NumberList>
          <StyledSpacer size="48px" />
          <EuiButton className='btn-text' fill onClick={onNextLanding}>Next</EuiButton>
        </Col>
        <StyledImageCol sm={{ span: 8 }} lg={{ span: 4 }} style={{ textAlign: 'center' }}>
          <EuiShowFor sizes={['l', 'xl']}>
            <EuiImage
              src={welcomeImg}
              alt="image_alt"
              size="xl"
            />
          </EuiShowFor>
          <EuiShowFor sizes={['m', 's', 'xs']}>
            <EuiImage
              src={welcomeImg}
              alt="image_alt"
              size="l"
            />
          </EuiShowFor>
          <StyledMobileEuiSpacer />
        </StyledImageCol>
      </StyledMobileRow>
    </>
  );
};

export default PersonalInfoLanding;