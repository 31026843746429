import React from 'react';
import { FieldArray, FormikErrors } from 'formik';
import FormError from '../Global/FormError';
import { EuiFormRow, EuiSuperSelect, EuiComboBox, EuiFormControlLayout } from '@elastic/eui';
import { GRAD_DATE_YEAR, GRAD_DATE_MONTH, UNDERGRAD_MAJORS, TOP_UNDERGRAD_UNIVERSITIES, HIGHEST_EDU_OPTIONS } from '../../constants/formOptions';
import { StyledBsAsterisk, StyledSpacer, StyledSpan, StyledEuiButton, StyledEuiLink, StyledEuiHorizontalRule, LabelAsterisk, LabelStyle } from '../Global/StyledComponents';
import styled from '@emotion/styled';

const StyledDualSelect = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    @media (max-width: 576px) {
        gap: 12px; 
    }
`
const StyledDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`
type EducationDetails = {
    university?: string;
    adv_deg?: string;
    major?: string;
    gradMonth?: string;
    gradYear?: string;
}[];

interface DegreeInfoProps {
    fieldName: 'education' | 'spouseEducation';
    spouseFirstName?: string;
    values: {
        education?: EducationDetails;
        spouseEducation?: EducationDetails;
    };
    errors: FormikErrors<{
        education?: EducationDetails;
        spouseEducation?: EducationDetails;
    }>;
    setFieldValue: (field: string, value: any) => void;
    submitCount: number;
}

function DegreeInfo({ values, errors, setFieldValue, submitCount, fieldName, spouseFirstName }: DegreeInfoProps) {

    return (
        <FieldArray name={fieldName}>
            {arrayHelpers => (
                <>
                    {values[fieldName]?.map((fieldItem, index) => (
                        <div key={index}>

                            <StyledSpacer size='32px' />

                            <EuiFormRow
                                label={<StyledSpan>Degree<StyledBsAsterisk /></StyledSpan>}
                                isInvalid={submitCount > 0 && !!(errors[fieldName]?.[index] as any)?.adv_deg}
                                className='input-size'
                            >
                                <EuiSuperSelect
                                    options={HIGHEST_EDU_OPTIONS}
                                    valueOfSelected={fieldItem.adv_deg}
                                    onChange={(value) => {
                                        setFieldValue(`${fieldName}[${index}].adv_deg`, value);
                                    }}
                                />
                            </EuiFormRow>
                            <FormError names={[`${fieldName}[${index}].adv_deg`]} />

                            <StyledSpacer size='32px' />

                            <EuiFormRow
                                label={<StyledSpan>College/University</StyledSpan>}
                                className='input-size'
                            >
                                <EuiComboBox
                                    rowHeight={40}
                                    isClearable={false}
                                    options={TOP_UNDERGRAD_UNIVERSITIES.map(university => ({ label: university.value }))}
                                    selectedOptions={fieldItem.university ? [{ label: fieldItem.university }] : []}
                                    onChange={options => {
                                        const selectedUniversity = options.length ? options[0].label : '';
                                        setFieldValue(`${fieldName}[${index}].university`, selectedUniversity);
                                    }}
                                    singleSelection={{ asPlainText: true }}
                                />
                            </EuiFormRow>
                            <FormError names={[`${fieldName}[${index}].university`]} />

                            <StyledSpacer size='32px' />

                            <EuiFormRow
                                label={<StyledSpan>Field of Study<StyledBsAsterisk /></StyledSpan>}
                                className='input-size'
                                isInvalid={submitCount > 0 && !!(errors[fieldName]?.[index] as any)?.major}
                            >
                                <EuiFormControlLayout>
                                    <EuiComboBox
                                        rowHeight={40}
                                        isClearable={false}
                                        options={UNDERGRAD_MAJORS.map(major => ({ label: major.value }))}
                                        selectedOptions={fieldItem.major ? [{ label: fieldItem.major }] : []}
                                        onChange={options => {
                                            const selectedMajor = options.length ? options[0].label : '';
                                            setFieldValue(`${fieldName}[${index}].major`, selectedMajor);
                                        }}
                                        singleSelection={{ asPlainText: true }}
                                    />
                                </EuiFormControlLayout>
                            </EuiFormRow>
                            <FormError names={[`${fieldName}[${index}].major`]} />


                            <StyledSpacer size='32px' />

                            <EuiFormRow
                                label={<LabelStyle>Graduation Date (or Expected)<LabelAsterisk /></LabelStyle>}
                                isInvalid={submitCount > 0 && (!!((errors[fieldName]?.[index] as any)?.gradMonth) || !!((errors[fieldName]?.[index] as any)?.gradYear))}
                            >
                                <StyledDualSelect>

                                    <StyledDiv className="inputWithError">
                                        <EuiFormControlLayout className='input-size-small'>
                                            <EuiComboBox
                                                rowHeight={40}
                                                isClearable={false}
                                                options={GRAD_DATE_MONTH.map(major => ({ label: major.value }))}
                                                selectedOptions={fieldItem.gradMonth ? [{ label: fieldItem.gradMonth }] : []}
                                                onChange={options => {
                                                    const selectedGradMonth = options.length ? options[0].label : '';
                                                    setFieldValue(`${fieldName}[${index}].gradMonth`, selectedGradMonth);
                                                }}
                                                singleSelection={{ asPlainText: true }}
                                            />
                                        </EuiFormControlLayout>
                                        <FormError names={[`${fieldName}[${index}].gradMonth`]} />
                                    </StyledDiv>

                                    <StyledDiv className="inputWithError">
                                        <EuiFormControlLayout className='input-size-small'>
                                            <EuiComboBox
                                                rowHeight={40}
                                                isClearable={false}
                                                options={GRAD_DATE_YEAR.map(major => ({ label: major.value }))}
                                                selectedOptions={fieldItem.gradYear ? [{ label: fieldItem.gradYear }] : []}
                                                onChange={options => {
                                                    const selectedGradYear = options.length ? options[0].label : '';
                                                    setFieldValue(`${fieldName}[${index}].gradYear`, selectedGradYear);
                                                }}
                                                singleSelection={{ asPlainText: true }}
                                            />
                                        </EuiFormControlLayout>

                                        <FormError names={[`${fieldName}[${index}].gradYear`]} />
                                        
                                    </StyledDiv>

                                </StyledDualSelect>
                            </EuiFormRow>

                            <StyledSpacer size='32px' />

                            {index > 0 && (
                                <>
                                    <StyledEuiLink onClick={() => arrayHelpers.remove(index)} color="danger">
                                        Delete
                                    </StyledEuiLink>
                                    <StyledSpacer size='24px' />
                                </>
                            )}

                            <StyledEuiHorizontalRule maxWidth="428px" />
                        </div>
                    ))}

                    <StyledSpacer size='32px' />

                    <StyledEuiButton color='text' iconType="plus" onClick={() => arrayHelpers.push({
                        university: '',
                        adv_deg: '',
                        major: '',
                        gradMonth: '',
                        gradYear: ''
                    })}>
                        Add degree
                    </StyledEuiButton>

                </>
            )}
        </FieldArray>
    );
}

export default DegreeInfo;
