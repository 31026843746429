import { EuiFormRow, EuiDatePicker, EuiFieldText } from '@elastic/eui';
import { Field, FieldArray, FieldProps, getIn, FormikErrors } from 'formik';
import { StyledSpacer, StyledSpan, StyledEuiButton, StyledBsAsterisk, StyledEuiLink, StyledEuiHorizontalRule } from '../Global/StyledComponents';
import FormError from '../Global/FormError';
import { useFieldReset } from '../../hooks/useFieldReset';

interface ChildInfoProps {
    values: {
        hasChildren?: string;
        children?: { name?: string; dob?: Date | undefined; }[];
    };
    errors: FormikErrors<{
        children?: { name?: string; dob?: Date | undefined; }[];
    }>;
    setFieldValue: (field: string, value: any) => void;
    validateField: (field: string) => void;
    submitCount: number;
}

function ChildInfo({ values, setFieldValue, errors, submitCount, validateField }: ChildInfoProps) {

    const fieldsToReset = values.children
        ? values.children.map((_, index) => [
            `children.${index}.name`,
            `children.${index}.dob`
        ]).flat()
        : [];

    useFieldReset(
        values.hasChildren !== 'HasChildren-Yes',
        fieldsToReset,
        setFieldValue,
        validateField
    );

    return (
        <>
            {values.hasChildren === 'HasChildren-Yes' && (
                <>
                    <StyledSpacer size='32px' />
                    
                    <FieldArray
                        name="children"
                        render={arrayHelpers => (
                            <>
                                {values.children?.map((child, index) => (
                                    <EuiFormRow key={index}>
                                        <div>
                                            {/* Child Name Input */}
                                            <EuiFormRow
                                                label={
                                                    <StyledSpan>Child's Name<StyledBsAsterisk />
                                                    </StyledSpan>}
                                            >
                                                <div>
                                                    <EuiFieldText
                                                        name={`children.${index}.name`}
                                                        type="text"
                                                        value={getIn(values, `children.${index}.name`)}
                                                        isInvalid={submitCount > 0 && !!getIn(errors, `children.${index}.name`)}
                                                        onChange={event => setFieldValue(`children.${index}.name`, event.target.value)}
                                                    />
                                                    <FormError names={[`children.${index}.name`]} />
                                                </div>
                                            </EuiFormRow>

                                            <StyledSpacer size='32px' />

                                            {/* Child Date of Birth Input */}
                                            <EuiFormRow
                                                label={<StyledSpan>Child's Date of Birth<StyledBsAsterisk /></StyledSpan>}
                                                helpText="Example: 05/20/1990"
                                            >
                                                <Field name={`children.${index}.dob`}>
                                                    {({ field }: FieldProps) => (
                                                        <div>
                                                            <EuiDatePicker
                                                                showIcon={false}
                                                                selected={field.value}
                                                                onChange={date => setFieldValue(`children.${index}.dob`, date)}
                                                                isInvalid={submitCount > 0 && !!getIn(errors, `children.${index}.dob`)}
                                                            />
                                                            <FormError names={[`children.${index}.dob`]} />
                                                        </div>
                                                    )}
                                                </Field>
                                            </EuiFormRow>

                                            <StyledSpacer size='32px' />

                                            {/* Remove Child Button */}
                                            <div>
                                                {index > 0 && (
                                                    <>
                                                        <StyledEuiLink onClick={() => arrayHelpers.remove(index)} color="danger">
                                                            Delete
                                                        </StyledEuiLink>
                                                        <StyledSpacer size='24px' />
                                                    </>
                                                )}
                                            </div>

                                            <StyledEuiHorizontalRule maxWidth="428px" />

                                            <StyledSpacer size='32px' />

                                            {/* Add Child Button */}
                                            {values.children && index === values.children.length - 1 && (
                                                <StyledEuiButton color='text' iconType="plus" onClick={() => arrayHelpers.push({ name: '', dob: null })}>
                                                    Add child
                                                </StyledEuiButton>
                                            )}
                                        </div>
                                    </EuiFormRow>
                                ))}
                            </>
                        )}
                    />
                </>
            )}
        </>
    );
};

export default ChildInfo;
