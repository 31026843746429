import { FormikErrors, FormikHelpers } from 'formik';
import { EuiFormRow, EuiRadioGroup } from '@elastic/eui';
import { StyledSpacer, LabelStyle, LabelAsterisk } from '../Global/StyledComponents';
import FormError from '../Global/FormError';

type HCQuestionsTypes = {
    requireLicense: string,
    passExam: string,
    haveDesignation: string,
    passDesignationExam: string,
    memberOrganization: string,
    collegeAthlete: string,
    runMarathon: string,
    servedMilitary: string,
    [key: string]: string;
}[];

interface HC_QUESTION {
    name: string;
    label: string;
    type: string;
    options: { id: string; label: string }[];
}

interface HCQuestionsProps {
    fieldName: 'humanCapital' | 'spouseHumanCapital';
    spouseFirstName?: string;
    question: HC_QUESTION;
    index: number;
    values: {
        humanCapital?: HCQuestionsTypes;
        spouseHumanCapital?: HCQuestionsTypes;
    };
    errors: FormikErrors<{
        humanCapital?: HCQuestionsTypes;
        spouseHumanCapital?: HCQuestionsTypes;
    }>;
    setFieldValue: FormikHelpers<any>['setFieldValue'];
    submitCount: number;
}

function HumanCapitalQuestions({ values, setFieldValue, fieldName, index, question }: HCQuestionsProps) {
    const shouldRenderQuestion = (questionName: string): boolean => {
        const fieldValue = values[fieldName]?.[0];

        switch (questionName) {
            case 'passExam':
                return fieldValue?.requireLicense === 'Yes';
            case 'passDesignationExam':
                return fieldValue?.haveDesignation === 'Yes';
            default:
                return true;
        }
    };
    
    if (!shouldRenderQuestion(question.name)) {
        return null;
    }

    const renderQuestionField = (question: HC_QUESTION) => {
        if (question.type === 'radio') {
            const radioOptions = question.options;

            const fieldValue = values[fieldName]?.[0];

            const selectedValue = fieldValue?.[question.name];

            const idSelected = selectedValue ? `${question.name}-${selectedValue}` : undefined;

            return (
                <EuiRadioGroup
                    options={radioOptions}
                    idSelected={idSelected}
                    onChange={(optionId: string) => {
                        const value = optionId.split('-').pop();
                        setFieldValue(`${fieldName}[0].${question.name}`, value);
                    }}
                />
            );
        }
        return null;
    };

    return (
        <div key={index}>
            <EuiFormRow
                label={<LabelStyle>{question.label}<LabelAsterisk /></LabelStyle>}
            >
                <>
                    {renderQuestionField(question)}
                </>
            </EuiFormRow>
            <FormError names={[`${fieldName}[0].${question.name}`]} />
            <StyledSpacer size='32px' />
        </div>
    );
}

export default HumanCapitalQuestions;
