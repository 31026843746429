import { configureStore } from '@reduxjs/toolkit';
import formReducer from '../reducers/formReducer';

const store = configureStore({
  reducer: {
    form: formReducer,
    // ... other reducers if any ...
  },
  // Middleware and DevTools configuration is handled automatically by Redux Toolkit
});

export type RootState = ReturnType<typeof store.getState>;
export default store;