import { useEffect, useState } from 'react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    position: relative;
`;

const ScoreLabel = styled.div`
    text-align: center;
    margin-top: -18px;
    font-size: 14px;
    font-weight: bold;
`;

interface FitbuxScoreProps {
    FitbuxScore: number;
}

const FitbuxScore = ({ FitbuxScore }: FitbuxScoreProps) => {
    const [clipPathPoints, setClipPathPoints] = useState("0,34 0,34 0,0 0,0");
    const [clipPathId, setClipPathId] = useState("");

    useEffect(() => {
        setClipPathId(`clip-${Math.random().toString(36).substr(2, 9)}`);
    }, []);

    useEffect(() => {
        const newWidth = 69 * (Math.max(0, Math.min(FitbuxScore, 1000)) / 1000);
        let newPoints = calculateClipPathPoints(newWidth);
        setClipPathPoints(newPoints);
    }, [FitbuxScore]);

    return (
        <Wrapper>
            <svg width="69" height="34" viewBox="0 0 69 34" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <clipPath id={clipPathId}>
                        <polygon points={clipPathPoints} />
                    </clipPath>
                </defs>
                <path d="M0 35C0 25.85 3.63481 17.0748 10.1048 10.6048C16.5748 4.13481 25.35 0.5 34.5 0.5C43.65 0.5 52.4252 4.13481 58.8952 10.6048C65.3652 17.0748 69 25.85 69 35L58.65 35C58.65 28.595 56.1056 22.4524 51.5766 17.9234C47.0476 13.3944 40.905 10.85 34.5 10.85C28.095 10.85 21.9524 13.3944 17.4234 17.9234C12.8944 22.4524 10.35 28.595 10.35 35H0Z" fill="#D3DAE6" />
                <path d="M0 35C0 25.85 3.63481 17.0748 10.1048 10.6048C16.5748 4.13481 25.35 0.5 34.5 0.5C43.65 0.5 52.4252 4.13481 58.8952 10.6048C65.3652 17.0748 69 25.85 69 35L58.65 35C58.65 28.595 56.1056 22.4524 51.5766 17.9234C47.0476 13.3944 40.905 10.85 34.5 10.85C28.095 10.85 21.9524 13.3944 17.4234 17.9234C12.8944 22.4524 10.35 28.595 10.35 35H0Z" fill="#0077CC" clipPath={`url(#${clipPathId})`} />
            </svg>
            <ScoreLabel>
                {FitbuxScore <= 0 ? 'N/A' : FitbuxScore}
            </ScoreLabel>
        </Wrapper>
    );
};

const calculateClipPathPoints = (newWidth: number) => {
    let newPoints = `0,34 ${newWidth},34 ${newWidth},0 0,0`;
    if (newWidth > 0 && newWidth <= 34.5) {
        newPoints = `0,34 ${newWidth + 15},34 ${newWidth - 15},0 0,0`;
    } else if (newWidth > 34.5 && newWidth < 69) {
        const adjustment = (newWidth - 34.5) * -1;
        newPoints = `0,34 ${newWidth + adjustment},34 ${newWidth - adjustment},0 0,0`;
    }
    return newPoints;
};

export default FitbuxScore;
