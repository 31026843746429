import {
    EuiFormRow,
    EuiRadioGroup,
    EuiFieldText,
    EuiDatePicker
} from '@elastic/eui';
import moment from 'moment';
import {
    MARITAL_STATUS_OPTIONS,
    IS_SPOUSE_MEMBER_OPTIONS,
    SPOUSE_GENDER_OPTIONS
} from '../../constants/formOptions';
import { StyledSpacer, StyledBsAsterisk, StyledSpan, StyledEuiCallOut, StyledLink } from '../Global/StyledComponents';
import FormError from '../Global/FormError';
import { useFieldReset } from '../../hooks/useFieldReset';

interface SpouseInfoProps {
    values: {
        isMarried?: string;
        isSpouseMember?: string;
        spouseFirstName?: string;
        spouseLastName?: string;
        spouseDateOfBirth?: Date | undefined;
        spouseGender?: string;
    };
    setFieldValue: (field: string, value: any) => void;
    errors: {
        isMarried?: string;
        isSpouseMember?: string;
        spouseFirstName?: string;
        spouseLastName?: string;
        spouseDateOfBirth?: string;
        spouseGender?: string;
    };
    submitCount: number;
    validateField: (field: string) => void;
}

function SpouseInfo({ values, setFieldValue, errors, submitCount, validateField }: SpouseInfoProps) {

    useFieldReset(
        values.isMarried === 'MaritalStatus-No',
        ['spouseFirstName', 'spouseDateOfBirth', 'spouseGender'],
        setFieldValue,
        validateField
    );

    return (
        <>
            <EuiFormRow
                label={<StyledSpan>Are you currently married?<StyledBsAsterisk /></StyledSpan>}
                isInvalid={submitCount > 0 && !!errors.isMarried}
                error={errors.isMarried ? [errors.isMarried] : undefined}

            >
                <EuiRadioGroup
                    name="isMarried"
                    options={MARITAL_STATUS_OPTIONS}
                    idSelected={values.isMarried}
                    onChange={value => setFieldValue('isMarried', value)}
                />
            </EuiFormRow>
            <FormError names={["isMarried"]} />

            {values.isMarried === 'MaritalStatus-Yes' && (
                <>
                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label={<StyledSpan>Is your spouse a FitBUX member?</StyledSpan>}
                        isInvalid={submitCount > 0 && !!errors.isSpouseMember}
                        error={errors.isSpouseMember ? [errors.isSpouseMember] : undefined}
                    >
                        <EuiRadioGroup
                            name="isSpouseMember"
                            options={IS_SPOUSE_MEMBER_OPTIONS}
                            idSelected={values.isSpouseMember}
                            onChange={value => setFieldValue('isSpouseMember', value)}
                        />
                    </EuiFormRow>

                </>
            )}

            {values.isSpouseMember === 'SpouseMember-Yes' && values.isMarried !== 'MaritalStatus-No' && (
                <>
                    <StyledSpacer size='32px' />
                    
                    <StyledEuiCallOut title="Join Spouse's FitBUX Account" iconType="iInCircle">
                        <p>If your spouse already has a FitBUX account, it's easier to join their account than to create a new one. After joining their account, send an email to <StyledLink href="mailto:info@fitbux.com">info@fitbux.com</StyledLink> to make sure you're only charged once. If you want to create a separate account, you can continue below.</p>
                    </StyledEuiCallOut>

                </>
            )}

            {(values.isSpouseMember === 'SpouseMember-Yes' || values.isSpouseMember === 'SpouseMember-No') && values.isMarried !== 'MaritalStatus-No' && (
                <>
                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        className='input-size'
                        label={<StyledSpan>Spouse's First Name<StyledBsAsterisk /></StyledSpan>}
                        isInvalid={submitCount > 0 && !!errors.spouseFirstName}
                        error={errors.spouseFirstName ? [errors.spouseFirstName] : undefined}
                    >
                        <EuiFieldText
                            name="spouseFirstName"
                            isInvalid={submitCount > 0 && !!errors.spouseFirstName}
                            value={values.spouseFirstName}
                            onChange={e => setFieldValue('spouseFirstName', e.target.value)}

                        />
                    </EuiFormRow>

                    <FormError names={["spouseFirstName"]} />

                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        className='input-size'
                        label={<StyledSpan>Spouse's Last Name</StyledSpan>}
                        isInvalid={submitCount > 0 && !!errors.spouseLastName}
                        error={errors.spouseLastName ? [errors.spouseLastName] : undefined}
                    >
                        <EuiFieldText
                            name="spouseLastName"
                            isInvalid={submitCount > 0 && !!errors.spouseLastName}
                            value={values.spouseLastName}
                            onChange={e => setFieldValue('spouseLastName', e.target.value)}
                        />
                    </EuiFormRow>

                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label={<StyledSpan>Spouse's Date of Birth<StyledBsAsterisk /></StyledSpan>}
                        helpText="Example: 05/20/1990"
                        isInvalid={submitCount > 0 && !!errors.spouseDateOfBirth}
                        error={errors.spouseDateOfBirth ? [errors.spouseDateOfBirth] : undefined}
                        className='input-size'
                    >
                        <div>
                            <EuiDatePicker
                                showIcon={false}
                                selected={values.spouseDateOfBirth ? moment(values.spouseDateOfBirth) : null}
                                onChange={date => setFieldValue('spouseDateOfBirth', date)}
                                isInvalid={submitCount > 0 && !!errors.spouseDateOfBirth}
                            />
                            {submitCount > 0 && errors.spouseDateOfBirth && (
                                <FormError names={["spouseDateOfBirth"]} />
                            )}
                        </div>
                    </EuiFormRow>

                    <StyledSpacer size='32px' />

                    <EuiFormRow
                        label={<StyledSpan>Spouse's Gender<StyledBsAsterisk /></StyledSpan>}
                        isInvalid={submitCount > 0 && !!errors.spouseGender}
                        error={errors.spouseGender ? [errors.spouseGender] : undefined}
                    >
                        <EuiRadioGroup
                            name="spouseGender"
                            options={SPOUSE_GENDER_OPTIONS}
                            idSelected={values.spouseGender}
                            onChange={value => setFieldValue('spouseGender', value)}
                        />
                    </EuiFormRow>
                    <FormError names={["spouseGender"]} />

                </>
            )}
        </>
    );
};

export default SpouseInfo;
