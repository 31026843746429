import { Formik, Form } from 'formik';
import { EuiButton, EuiButtonEmpty, EuiSpacer, EuiText, EuiTitle, EuiFormRow, EuiFlexGroup, EuiFlexItem, EuiFieldText } from '@elastic/eui';
import AdditionalIncome from './AdditionalIncome';
import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButtonEmpty, LabelStyle, LabelAsterisk } from '../Global/StyledComponents';
import { Global, css } from '@emotion/react';
import { handleCurrencyBlur, handleAnnualSalaryUpdate } from '../../utils/currencyFields';
import FormError from '../Global/FormError';

const globalStyles = css`
    @media (max-width: 768px) {
        .mobile-field-row {
            flex-wrap: nowrap !important;
        }
    }
`;

interface FinancialInfoFormValues {
    monthlySalary: string,
    annualSalary: string,
    additionalIncome: {
        incomeType: string,
        monthlySalary: string,
        annualSalary: string,
    }[];
}

interface FinancialInfoProps {
    initialValues: FinancialInfoFormValues;
    onNext: (values: FinancialInfoFormValues) => void;
    prevStep: () => void;
}

function FinancialInfo({ initialValues, onNext, prevStep }: FinancialInfoProps) {

    const handleSubmit = (formValues: FinancialInfoFormValues) => {
        onNext(formValues);
    }

    const formikConfig = useValidationForm(FormType.FinancialInfo, handleSubmit);

    return (
        <>
            <Global styles={globalStyles} />
            <StyledFormRow>
                <Col xl={{ span: 5, offset: 2 }} lg={{ span: 8, offset: 1 }} md={{ span: 8 }}>
                    <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                        Back
                    </EuiButtonEmpty>
                    <StyledSpacer size="32px" />
                    <EuiTitle size="l" className='header-font'>
                        <h1>Chris's Income</h1>
                    </EuiTitle>
                    <EuiSpacer size='l' />
                    <EuiText size='m'>
                        <p>Enter Chris's total income. Total income is the amount you get paid before deductions such as taxes, social security, retirement contributions, etc.</p>
                    </EuiText>
                    <StyledSpacer size='36px' />
                    <Formik
                        initialValues={initialValues}
                        validationSchema={formikConfig.validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            setFieldValue,
                            values,
                            errors,
                            isSubmitting,
                            isValid,
                            submitCount,
                        }) => {

                            return (
                                <Form>

                                    <EuiFormRow
                                        label={<LabelStyle>What is your current or future salary?<LabelAsterisk /></LabelStyle>}
                                        className='input-size'
                                    >
                                        <>
                                            <EuiFlexGroup style={{ maxWidth: 428, marginTop: 16 }} className='mobile-field-row'>
                                                <EuiFlexItem>
                                                    <EuiFormRow label="Monthly">
                                                        <EuiFieldText
                                                            name="monthlySalary"
                                                            value={values.monthlySalary}
                                                            onChange={e => {
                                                                setFieldValue("monthlySalary", e.target.value);
                                                                handleAnnualSalaryUpdate(e.target.value, "annualSalary", setFieldValue);
                                                            }}
                                                            onBlur={(e) => handleCurrencyBlur('monthlySalary', setFieldValue)(e)}
                                                            isInvalid={submitCount > 0 && !!errors.monthlySalary}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                                <EuiFlexItem>
                                                    <EuiFormRow label="Annual">
                                                        <EuiFieldText
                                                            name="annualSalary"
                                                            value={values.annualSalary}
                                                            isInvalid={submitCount > 0 && !!errors.annualSalary}
                                                        />
                                                    </EuiFormRow>
                                                </EuiFlexItem>
                                            </EuiFlexGroup>
                                            <FormError names={["monthlySalary", "annualSalary"]} />
                                        </>

                                    </EuiFormRow>

                                    <StyledSpacer size="32px" />

                                    <AdditionalIncome
                                        fieldName="additionalIncome"
                                        values={values}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        submitCount={submitCount}
                                    />

                                    <StyledSpacer size='48px' />

                                    <EuiButton
                                        type="submit"
                                        color='primary'
                                        fill
                                        isLoading={isSubmitting}
                                        disabled={(submitCount > 0 && !isValid) || isSubmitting}
                                    >
                                        Next
                                    </EuiButton>

                                    <StyledEuiButtonEmpty color="text">
                                        Save and Exit
                                    </StyledEuiButtonEmpty>

                                    <StyledSpacer size='32px' />

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                </Form>
                            );

                        }}
                    </Formik>
                </Col>
            </StyledFormRow >
        </>
    );
}

export default FinancialInfo;