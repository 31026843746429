import { createReducer } from '@reduxjs/toolkit';
import { updateFormValues } from '../actions/actions';
import { updateDebtAccounts } from '../actions/actions';
import { addDebtAccount } from '../actions/actions';
import { expenseCategories } from '../../constants/formOptions';

export const initialState = {
    dateOfBirth: undefined,
    zipCode: '',
    gender: '',
    isMarried: '',
    isSpouseMember: '',
    spouseFirstName: '',
    spouseLastName: '',
    spouseGender: '',
    spouseDateOfBirth: undefined,
    hasChildren: '',
    children: [{ name: '', dob: undefined }],
    degree: '',
    education: [
        { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
    ],
    spouseDegree: '',
    spouseEducation: [
        { university: '', major: '', gradMonth: '', gradYear: '', adv_deg: '' }
    ],
    monthlySalary: '',
    annualSalary: '',
    additionalIncome: [{
        incomeType: '',
        monthlySalary: '',
        annualSalary: '',
    }],
    spouseMonthlySalary: '',
    spouseAnnualSalary: '',
    spouseAdditionalIncome: [{
        incomeType: '',
        monthlySalary: '',
        annualSalary: '',
    }],
    householdExpenses: expenseCategories.map(category => ({
        category: category.label,
        monthlyExpense: '',
        annualExpense: '',
    })),
    customExpense: [{
        expenseType: '',
        monthlyExpense: '',
        annualExpense: ''
    }],
    humanCapital: [{
        profession: '',
        requireLicense: '',
        passExam: '',
        haveDesignation: '',
        passDesignationExam: '',
        memberOrganization: '',
        collegeAthlete: '',
        runMarathon: '',
        servedMilitary: '',
    }],
    spouseHumanCapital: [{
        profession: '',
        requireLicense: '',
        passExam: '',
        haveDesignation: '',
        passDesignationExam: '',
        memberOrganization: '',
        collegeAthlete: '',
        runMarathon: '',
        servedMilitary: '',
    }],
    wasApScholar: '',
    graduatedFromIbProgram: '',
    offeredMeritOrAcademicScholarship: '',
    offeredFullTuitionScholarship: '',
    spousewasApScholar: '',
    spousegraduatedFromIbProgram: '',
    spouseofferedMeritOrAcademicScholarship: '',
    spouseofferedFullTuitionScholarship: '',
    otherLanguage: '',
    multipleLanguages: '',
    languages: [{
        langName: '',
        speakingLevel: '',
        readingLevel: '',
        writingLevel: '',
    }],
    spouseOtherLanguage: '',
    spouseMultipleLanguages: '',
    spouseLanguages: [{
        langName: '',
        speakingLevel: '',
        readingLevel: '',
        writingLevel: '',
    }],
    useFinancialSoftware: '',
    delinquentLoan: '',
    spouseUseFinancialSoftware: '',
    spouseDelinquentLoan: '',
    debtAccounts: [{
        accountType: '',
        owner: '',
        planOptions: '',
        financeInst: '',
        accountName: '',
        balance: '',
        payOffMonthly: false,
        interestRate: '',
        minPayment: '',
        isResponsible: '',
        loanInfoType: '',
        loanDetails: [{
            currentBalance: '',
            interestRate: '',
        }],
        estMarkeValue: '',
    }],
};

const formReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(updateFormValues, (state, action) => {
            Object.assign(state, action.payload);
        })
        .addCase(updateDebtAccounts, (state, action) => {
            state.debtAccounts = action.payload.debtAccounts;
            console.log("Updating debt accounts with:", action.payload.debtAccounts);
        })
        .addCase(addDebtAccount, (state) => {
            const newAccount = { ...initialState.debtAccounts[0], loanDetails: initialState.debtAccounts[0].loanDetails.map(detail => ({ ...detail })) };
            state.debtAccounts.push(newAccount);
        });
                
});

export default formReducer;