import { Formik, Form } from 'formik';
import { EuiButton, EuiButtonEmpty, EuiTitle, EuiText, EuiSpacer, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButtonEmpty, StyledEuiHorizontalRule, StyledEuiTitle } from '../Global/StyledComponents';
import { handleCurrencyBlur, handleAnnualSalaryUpdate } from '../../utils/currencyFields';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import CustomExpense from './CustomExpense';
import useWindowSize from '../../hooks/useWindowSize';
import { createExpenseField } from '../../utils/formFields';

const globalStyles = css`
    @media (max-width: 576px) {
        .mobile-group {
            flex-direction: column !important;
        }

        .mobile-group .mobile-field {
            flex: 1 0 50% !important;
            max-width: calc(50% - 4px) !important;
            margin-right: 4px !important;
        }

        .mobile-group .mobile-field:last-child {
            margin-right: 0 !important;
        }

        .mobile-field-row {
            flex-wrap: nowrap !important;
        }
    }
`;

const StyledEuiFlexGroup = styled(EuiFlexGroup)`
  padding-right: 44px;
`;

interface HouseholdExpenseFormValues {
    householdExpenses: {
        category: string;
        monthlyExpense: string;
        annualExpense: string;
    }[];
    customExpense: {
        expenseType: string;
        monthlyExpense: string;
        annualExpense: string;
    }[];
}

interface HouseholdExpenseInfoProps {
    initialValues: HouseholdExpenseFormValues;
    onNext: (values: HouseholdExpenseFormValues) => void;
    prevStep: () => void;
    expenseCategories: { key: string; label: string; }[];
}

function ExpenseInfo({ initialValues, onNext, prevStep, expenseCategories }: HouseholdExpenseInfoProps) {

    const { width } = useWindowSize();
    const isMobileView = width <= 768;


    const handleSubmit = (formValues: HouseholdExpenseFormValues) => {
        onNext(formValues);
    }

    return (
        <>
            <Global styles={globalStyles} />
            <StyledFormRow>
                <Col xl={{ span: 5, offset: 2 }} lg={{ span: 8, offset: 1 }} md={{ span: 12 }}>
                    <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                        Back
                    </EuiButtonEmpty>
                    <StyledSpacer size="32px" />
                    <EuiTitle size="l" className='header-font'>
                        <h1>Household expenses</h1>
                    </EuiTitle>
                    <EuiSpacer size='l' />
                    <EuiText size='m'>
                        <p>Enter either the monthly or annual amount rounded to the nearest dollar and the other field will complete automatically.</p>
                    </EuiText>

                    <StyledSpacer size='36px' />

                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({
                            setFieldValue,
                            values,
                            errors,
                            isSubmitting,
                            isValid,
                            submitCount,
                        }) => {

                            return (
                                <Form>

                                    {expenseCategories.map((category, index) => (
                                        <div key={category.key}>
                                            <StyledEuiFlexGroup justifyContent="spaceBetween"
                                                alignItems="flexEnd"
                                                className={isMobileView ? 'mobile-group' : ''}>
                                                <EuiFlexItem grow={2}>
                                                    <StyledEuiTitle size="xs"><h3>{category.label}</h3></StyledEuiTitle>
                                                </EuiFlexItem>

                                                {isMobileView ? (
                                                    <EuiFlexGroup className='mobile-field-row'>
                                                        {createExpenseField(
                                                            `householdExpenses[${index}].monthlyExpense`,
                                                            values.householdExpenses[index]?.monthlyExpense,
                                                            e => {
                                                                setFieldValue(`householdExpenses[${index}].monthlyExpense`, e.target.value);
                                                                handleAnnualSalaryUpdate(e.target.value, `householdExpenses[${index}].annualExpense`, setFieldValue);
                                                            },
                                                            e => handleCurrencyBlur(`householdExpenses[${index}].monthlyExpense`, setFieldValue)(e),
                                                            index,
                                                            isMobileView,
                                                            "Monthly"
                                                        )}
                                                        {createExpenseField(
                                                            `householdExpenses[${index}].annualExpense`,
                                                            values.householdExpenses[index]?.annualExpense,
                                                            e => setFieldValue(`householdExpenses[${index}].annualExpense`, e.target.value),
                                                            e => handleCurrencyBlur(`householdExpenses[${index}].annualExpense`, setFieldValue)(e),
                                                            index,
                                                            isMobileView,
                                                            "Annual"
                                                        )}
                                                    </EuiFlexGroup>
                                                ) : (
                                                    <>
                                                        {createExpenseField(
                                                            `householdExpenses[${index}].monthlyExpense`,
                                                            values.householdExpenses[index]?.monthlyExpense,
                                                            e => {
                                                                setFieldValue(`householdExpenses[${index}].monthlyExpense`, e.target.value);
                                                                handleAnnualSalaryUpdate(e.target.value, `householdExpenses[${index}].annualExpense`, setFieldValue);
                                                            },
                                                            e => handleCurrencyBlur(`householdExpenses[${index}].monthlyExpense`, setFieldValue)(e),
                                                            index,
                                                            isMobileView,
                                                            "Monthly"
                                                        )}
                                                        {createExpenseField(
                                                            `householdExpenses[${index}].annualExpense`,
                                                            values.householdExpenses[index]?.annualExpense,
                                                            e => setFieldValue(`householdExpenses[${index}].annualExpense`, e.target.value),
                                                            e => handleCurrencyBlur(`householdExpenses[${index}].annualExpense`, setFieldValue)(e),
                                                            index,
                                                            isMobileView,
                                                            "Annual"
                                                        )}
                                                    </>
                                                )}

                                            </StyledEuiFlexGroup>

                                            <StyledSpacer size='32px' />

                                            <StyledEuiHorizontalRule />

                                            <StyledSpacer size='32px' />

                                        </div>
                                    ))}

                                    <CustomExpense
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />

                                    <StyledSpacer size='48px' />

                                    <EuiButton
                                        type="submit"
                                        color='primary'
                                        fill
                                        isLoading={isSubmitting}
                                    >
                                        Next
                                    </EuiButton>

                                    <StyledEuiButtonEmpty color="text">
                                        Save and Exit
                                    </StyledEuiButtonEmpty>

                                    <StyledSpacer size='32px' />

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                </Form>
                            );

                        }}
                    </Formik>
                </Col>
            </StyledFormRow >
        </>
    );
}

export default ExpenseInfo;