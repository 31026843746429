import { Formik, Form } from 'formik';
import FormError from '../Global/FormError';
import { useValidationForm, FormType } from '../../hooks/useValidationForm';
import {
    EuiFormRow, EuiButton,
    EuiFormControlLayout, EuiButtonEmpty, EuiSpacer, EuiText, EuiTitle, EuiRadioGroup
} from '@elastic/eui';
import DegreeInfo from './DegreeInfo';
import { Col } from 'react-bootstrap';
import { DEGREE_OPTIONS } from '../../constants/formOptions';
import { StyledSpacer, StyledFormRow, LabelAsterisk, LabelStyle } from '../Global/StyledComponents';

interface EducationInfoFormValues {
    degree: string
    education: { university: string, major: string, gradMonth: string, gradYear: string, adv_deg: string }[];
}

interface EducationInfoProps {
    initialValues: EducationInfoFormValues;
    onNext: (values: EducationInfoFormValues) => void;
    prevStep: () => void;
}

function EducationInfo({ initialValues, onNext, prevStep }: EducationInfoProps) {

    const handleSubmit = (formValues: EducationInfoFormValues) => {
        onNext(formValues);
    }

    const formikConfig = useValidationForm(FormType.EducationInfo, handleSubmit);

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    <h1>Your education</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    <p>Your education reduces the risk to your income. The risk to your income affects our recommendations for student loan repayment plans, investments, and more.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    validationSchema={formikConfig.validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>
                                <EuiFormRow
                                    label={<LabelStyle>Have you completed or are you pursuing a degree?<LabelAsterisk /></LabelStyle>}
                                    isInvalid={submitCount > 0 && !!errors.education}
                                    className='input-size'
                                >
                                    <EuiFormControlLayout>

                                        <EuiRadioGroup
                                            name="degree"
                                            options={DEGREE_OPTIONS}
                                            idSelected={values.degree}
                                            onChange={value => setFieldValue('degree', value)}
                                        />

                                    </EuiFormControlLayout>

                                </EuiFormRow>
                                
                                <FormError names={["degree"]} />

                                {values.degree === 'Yes' && (
                                    <DegreeInfo
                                        fieldName="education"
                                        values={values}
                                        errors={errors}
                                        setFieldValue={setFieldValue}
                                        submitCount={submitCount}
                                    />
                                )}

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    disabled={(submitCount > 0 && !isValid) || isSubmitting}
                                >
                                    Next
                                </EuiButton>

                                <StyledSpacer size='32px' />

                                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default EducationInfo;