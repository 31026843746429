import { Formik, Form } from 'formik';
import { EuiButtonEmpty, EuiTitle, EuiSpacer, EuiText, EuiFormRow, EuiButton, EuiRadioGroup } from '@elastic/eui';
import { Col } from 'react-bootstrap';
import { StyledSpacer, StyledFormRow, StyledEuiButton } from '../Global/StyledComponents';
import { SPOUSE_EDU_OPTIONS_AP, SPOUSE_EDU_OPTIONS_GRAD, SPOUSE_EDU_OPTIONS_SCHOLARSHIP, SPOUSE_EDU_OPTIONS_TUITION } from '../../constants/formOptions';

interface HCEducationFormValues {
    spouseFirstName?: string;
    spousewasApScholar: string,
    spousegraduatedFromIbProgram: string,
    spouseofferedMeritOrAcademicScholarship: string,
    spouseofferedFullTuitionScholarship: string,
}

interface HCEducationProps {
    initialValues: HCEducationFormValues;
    onNext: (values: HCEducationFormValues) => void;
    prevStep: () => void;
}

function SpouseHCEducation({ initialValues, onNext, prevStep }: HCEducationProps) {

    const handleSubmit = (formValues: HCEducationFormValues) => {
        onNext(formValues);
    }

    return (
        <StyledFormRow>
            <Col lg={{ span: 5, offset: 2 }}>
                <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
                    Back
                </EuiButtonEmpty>
                <StyledSpacer size="32px" />
                <EuiTitle size="l" className='header-font'>
                    {/* <h1>{initialValues.spouseFirstName}'s Income</h1> */}
                    <h1>Tonya's Education</h1>
                </EuiTitle>
                <EuiSpacer size='l' />
                <EuiText size='m'>
                    {/* <p>Our data indicates that these factors reduce the risk to {initialValues.spouseFirstName}'s income.</p> */}
                    <p>Our data indicates that these factors reduce the risk to Tonya's income.</p>
                </EuiText>
                <StyledSpacer size='36px' />
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        submitCount,
                        validateField
                    }) => (
                        <>
                            <Form>

                                <EuiFormRow
                                    // label={Was {initialValues.spouseFirstName} an AP Scholar?}
                                    label="Was Tonya an AP Scholar?"
                                >
                                    <EuiRadioGroup
                                        name="spousewasApScholar"
                                        options={SPOUSE_EDU_OPTIONS_AP}
                                        idSelected={values.spousewasApScholar}
                                        onChange={value => setFieldValue('spousewasApScholar', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    // label={ Did {initialValues.spouseFirstName} graduate from an international baccalaureate program? }
                                    label="Did Tonya graduate from an international baccalaureate program?"
                                >
                                    <EuiRadioGroup
                                        name="spousegraduatedFromIbProgram"
                                        options={SPOUSE_EDU_OPTIONS_GRAD}
                                        idSelected={values.spousegraduatedFromIbProgram}
                                        onChange={value => setFieldValue('spousegraduatedFromIbProgram', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    // label={ Was {initialValues.spouseFirstName} offered a merit or academic scholarship for college? }
                                    label="Was Tonya offered a merit or academic scholarship for college?"
                                >
                                    <EuiRadioGroup
                                        name="spouseofferedMeritOrAcademicScholarship"
                                        options={SPOUSE_EDU_OPTIONS_SCHOLARSHIP}
                                        idSelected={values.spouseofferedMeritOrAcademicScholarship}
                                        onChange={value => setFieldValue('spouseofferedMeritOrAcademicScholarship', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='32px' />

                                <EuiFormRow
                                    // label={ Was {initialValues.spouseFirstName} offered scholarship that covered your entire tuition cost? }
                                    label="Was Tonya offered a scholarship that covered your entire tuition cost?"
                                >
                                    <EuiRadioGroup
                                        name="spouseofferedFullTuitionScholarship"
                                        options={SPOUSE_EDU_OPTIONS_TUITION}
                                        idSelected={values.spouseofferedFullTuitionScholarship}
                                        onChange={value => setFieldValue('spouseofferedFullTuitionScholarship', value)}
                                    />
                                </EuiFormRow>

                                <StyledSpacer size='48px' />

                                <EuiButton
                                    type="submit"
                                    color='primary'
                                    fill
                                    isLoading={isSubmitting}
                                    className='btn-text'
                                >
                                    Next
                                </EuiButton>

                                <StyledEuiButton color='text'>
                                    Finish
                                </StyledEuiButton>

                                {/* <StyledSpacer size='48px' />

                                <pre>{JSON.stringify(values, null, 2)}</pre> */}

                            </Form>
                        </>
                    )}
                </Formik>
            </Col>
        </StyledFormRow>
    );
}

export default SpouseHCEducation;