import { EuiTitle, EuiText, EuiButton, EuiImage, EuiSpacer, EuiShowFor, EuiButtonEmpty } from '@elastic/eui';
import { Row, Col } from 'react-bootstrap';
import styled from '@emotion/styled';
import FinancialInfoImage from '../assets/finance-info.svg';
import { StyledSpacer } from '../components/Global/StyledComponents'

const NumberList = styled.ol`
  list-style: none;
  padding-left: 0;

  li {
    display: flex;
    align-items: baseline;
    
    b {
      font-weight: bold;
    }
    
    span {
      margin-left: 8px;
    }
  }
`;
const StyledEuiText = styled(EuiText)`
  font-size: 22px;
  line-height: 2.4rem;
  .landing-text {
    max-width: 600px;
    button {
      font-weight: 400;
    }
  }

  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 1.8rem;
  }
`;
const StyledMobileRow = styled(Row)`
  @media (max-width: 576px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;
const StyledMobileEuiSpacer = styled(EuiSpacer)`
  @media (max-width: 576px) {
      block-size: 36px;
  }
`;
const StyledImageCol = styled(Col)`
  @media (max-width: 992px) {
    order: -1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .align-left {
      align-self: flex-start;
    }

    .align-center {
      align-self: center;
    }
    .center-wrapper {
      align-self: center;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  @media (min-width: 576px) and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

interface FinancialInfoLandingProps {
  nextStep: () => void;
  prevStep: () => void;
}

function FinancialInfoLanding({ nextStep, prevStep }: FinancialInfoLandingProps) {

  return (
    <>
      <StyledMobileRow className="justify-content-sm-center align-items-sm-center">
        <Col sm={{ span: 8 }} lg={{ span: 7 }}>
          <EuiShowFor sizes={['l', 'xl']}>
            <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep}>
              Back
            </EuiButtonEmpty>
            <StyledSpacer size="64px" />
          </EuiShowFor>
          <EuiTitle size="l" className='header-font'>
            <h1>Set up financial plan</h1>
          </EuiTitle>
          <EuiSpacer size='l' />
          <StyledEuiText size='m'>
            <div className='landing-text'>
              We'll ask you some questions about your budget, assets, and debts to create a long-term financial plan.
            </div>
          </StyledEuiText>
          <StyledSpacer size="48px" />
          <NumberList>
            <StyledEuiText size='m'>
              <li>1. <span>Personal Info</span></li>
              <EuiSpacer size='s' />
              <li><b>2. </b><span><b>Financial Info</b></span></li>
              <EuiSpacer size='s' />
              <li>3. <span>Human Capital</span></li>
            </StyledEuiText>
          </NumberList>
          <StyledSpacer size="48px" />
          <EuiButton className='btn-text' fill onClick={nextStep}>Next</EuiButton>
          <EuiButtonEmpty color="text" className='btn-text'>
            Save and Exit
          </EuiButtonEmpty>
        </Col>
        <StyledImageCol sm={{ span: 8 }} lg={{ span: 4 }}>
          <EuiShowFor sizes={['l', 'xl']}>
            <StyledSpacer size="64px" />
            <EuiImage
              src={FinancialInfoImage}
              alt="image_alt"
              size="xl"
            />
          </EuiShowFor>
          <EuiShowFor sizes={['m', 's', 'xs']}>
            <EuiButtonEmpty color="text" flush="left" iconType="arrowLeft" onClick={prevStep} className='align-left'>
              Back
            </EuiButtonEmpty>
            <div className='center-wrapper'>
              <EuiImage
                src={FinancialInfoImage}
                alt="image_alt"
                size="l"
                className='align-center'
              />
            </div>
          </EuiShowFor>
          <StyledMobileEuiSpacer />
        </StyledImageCol>
      </StyledMobileRow>
    </>
  );
};

export default FinancialInfoLanding;