import { useState } from 'react';
import { EuiFlyout, EuiFlyoutHeader, EuiFlyoutBody, EuiTitle, EuiIcon, EuiButton, EuiFlyoutFooter, EuiSpacer } from '@elastic/eui';
import { useFlyout } from '../Global/FlyoutContext';
import ManualAccountForm from './ManualAccountForm';
import { ManualAccountFormValues } from './ManualAccountForm';
import styled from '@emotion/styled';
import { StyledEuiButton } from '../Global/StyledComponents';

const StyledFlyoutHeader = styled(EuiFlyoutHeader)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledFlyoutBody = styled(EuiFlyoutBody)`
  padding: 0 24px;
`;

const StyledFlyout = styled(EuiFlyout)`
  top: 0;
  position: fixed;
  z-index: 1000;

  @media (max-width: 576px) {
    width: 100vw !important;
    max-inline-size: 100vw !important;
  }

  @media (min-width: 576px) and (max-width: 992px) {
    width: 70vw !important;
    max-inline-size: 70vw !important;
}
`;
const StyledEuiIcon = styled(EuiIcon)`
    width: 24px;
    height: 24px;
`;
const StyledEuiFlyoutFooter = styled(EuiFlyoutFooter)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: transparent;
  border-top: 1px solid #E9EDF3;
`;

interface DebtFlyoutProps {
    onDebtAccountAdded: (debtAccountData: ManualAccountFormValues['debtAccounts'][0]) => void;
    initialValues: ManualAccountFormValues;
}

function DebtFlyout({ onDebtAccountAdded, initialValues }: DebtFlyoutProps) {

    const [submitForm, setSubmitForm] = useState<() => void>();
    const { isDebtFlyoutOpen, closeDebtFlyout } = useFlyout();

    const handleFormSubmit = (formSubmitFunction: () => void) => {
        setSubmitForm(() => formSubmitFunction);
    };

    console.log('DebtFlyout', onDebtAccountAdded);

    const handleAddClick = () => {
        if (submitForm) {
            submitForm();
        }
        closeDebtFlyout();
    };    

    if (!isDebtFlyoutOpen) return null;

    return (
        <StyledFlyout className='customFlyout' ownFocus={false} size="s" onClose={closeDebtFlyout} onDebtAccountAdded={onDebtAccountAdded}>
            <StyledFlyoutHeader hasBorder>
                <EuiTitle size="m">
                    <h2>Add Manual Account</h2>
                </EuiTitle>
                <StyledEuiIcon
                    type="cross"
                    onClick={closeDebtFlyout}
                    style={{
                        cursor: 'pointer'
                    }}
                />
            </StyledFlyoutHeader>
            <StyledFlyoutBody>
                <>
                    <ManualAccountForm onDebtAccountAdded={onDebtAccountAdded} initialValues={initialValues} onFormSubmit={handleFormSubmit} />
                </>
                <EuiSpacer size='l' />
            </StyledFlyoutBody>
            <StyledEuiFlyoutFooter>
                <StyledEuiButton
                    color='text'
                    className='btn-spacing'
                    onClick={closeDebtFlyout}
                >
                    Cancel
                </StyledEuiButton>
                <EuiButton color='primary' fill
                    onClick={handleAddClick}
                >
                    Add
                </EuiButton>
            </StyledEuiFlyoutFooter>
        </StyledFlyout>
    );
};

export default DebtFlyout;